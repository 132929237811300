import { alterStudyProgramMetadataLocalizationsInDictionary } from "@/dictionary";
import {
  ActivityLocationLocalizedDTO,
  EmailTemplateOverviewDto,
  LocalizedActivityTypeDTO,
  LocalizedLanguage,
  LocalizedLanguageDTO,
  LocalizedSessionTypeDto,
  LocalizedStudyProgramDTO,
  TargetAudienceDTO,
} from "@/lib/eduConfigurationServiceClient";
import { FormType, LocalizedFormDTO } from "@/lib/formsServiceClient";
import { MailingType } from "@/models/mailingType";
import { StudyProgramMetadataLocalization } from "@/models/study-program-metadata-localization";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import { getStudyProgramMetadataLocalizations } from "@/services/localizations.service";
import { getMailingTypes } from "@/services/mailings.service";
import HttpClient from "@/utils/httpClient";
import { reactive } from "vue";

interface SettingsContext {
  localizations: StudyProgramMetadataLocalization;
  languages: LocalizedLanguageDTO[];
  availableLanguages: LocalizedLanguage[];
  mainLanguage: LocalizedLanguage;
  mailingTypes: MailingType[];
  allStudyPrograms: LocalizedStudyProgramDTO[];
  studyPrograms: LocalizedStudyProgramDTO[];
  activityTypes: LocalizedActivityTypeDTO[];
  activityLocations: ActivityLocationLocalizedDTO[];
  sessionTypes: LocalizedSessionTypeDto[];
  forms: LocalizedFormDTO[];
  emailTemplates: EmailTemplateOverviewDto[];
  targetAudiences: TargetAudienceDTO[];
}

const settings: SettingsContext = reactive<SettingsContext>({
  localizations: {} as StudyProgramMetadataLocalization,
  languages: [],
  availableLanguages: [],
  mainLanguage: {} as LocalizedLanguage,
  mailingTypes: [],
  allStudyPrograms: [],
  studyPrograms: [],
  activityTypes: [],
  activityLocations: [],
  sessionTypes: [],
  forms: [],
  emailTemplates: [],
  targetAudiences: [],
});

export default settings;

export async function loadSettings() {
  await Promise.all([
    loadLocalizations(),
    loadLanguages(),
    loadAvailableLanguages(),
    loadMailingTypes(),
    loadStudyPrograms(),
    loadActivityTypes(),
    loadActivityLocations(),
    loadSessionTypes(),
    loadForms(),
    loadEmailTemplates(),
    loadTargetAudiences(),
  ]);
}

export async function loadLocalizations() {
  settings.localizations = await getStudyProgramMetadataLocalizations();

  alterStudyProgramMetadataLocalizationsInDictionary(settings.localizations);
}

export async function loadLanguages() {
  settings.languages = (await HttpClient.getLanguages()).data;
}

export async function loadAvailableLanguages() {
  const availableLanguages =
    await eduConfigurationServiceClient.getAvailableLanguages();

  settings.availableLanguages = [
    availableLanguages.mainLanguage,
    ...availableLanguages.otherLanguages,
  ];

  settings.mainLanguage = availableLanguages.mainLanguage;
}

export async function loadMailingTypes() {
  settings.mailingTypes = await getMailingTypes();
}

export async function loadStudyPrograms() {
  settings.allStudyPrograms =
    await eduConfigurationServiceClient.getStudyPrograms(true);
  settings.studyPrograms = settings.allStudyPrograms.filter(
    (sp) => sp.isArchived === false,
  );
}

export async function loadActivityTypes() {
  settings.activityTypes =
    await eduConfigurationServiceClient.getActivityTypes();
}

export async function loadActivityLocations() {
  settings.activityLocations = await eduConfigurationServiceClient.getAll();
}

export async function loadSessionTypes() {
  settings.sessionTypes = await eduConfigurationServiceClient.getSessionTypes();
}

export async function loadForms() {
  settings.forms = await formsServiceClient.getForms(FormType.Registration);
}

export async function loadEmailTemplates() {
  settings.emailTemplates =
    await eduConfigurationServiceClient.getEmailTemplates();
}

export async function loadTargetAudiences() {
  settings.targetAudiences =
    await eduConfigurationServiceClient.getTargetAudiences();
}
