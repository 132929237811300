<template>
  <NavigationTabContainer>
    <NavigationTab
      v-for="culture in localizations"
      v-show="
        settings.availableLanguages.map((l) => l.locale.value).includes(culture)
      "
      :key="culture"
      :data-testid="testIds.action.view"
      :current="model === culture"
      @click="model = culture"
    >
      <div class="flex items-center gap-2">
        <span>{{ texts.enums.culture[culture] }}</span>
        <button
          v-if="culture !== defaultCulture"
          type="button"
          :data-testid="testIds.action.delete"
          @click.stop="remove(culture)"
        >
          <Icon
            icon="close"
            :size="IconSize.xs"
            :color="Color.DeepTeal"
            clickable
          ></Icon>
        </button>
      </div>
    </NavigationTab>
    <NavigationTab
      v-for="culture in notAddedCultures"
      :key="culture"
      icon="add_circle"
      :name="interpolate(texts.actions.add, texts.enums.culture[culture])"
      :data-testid="testIds.action.create"
      @click="add(culture)"
    />
  </NavigationTabContainer>
</template>

<script setup lang="ts" generic="T">
import NavigationTab from "@/components/common/navigation/NavigationTab.vue";
import NavigationTabContainer from "@/components/common/navigation/NavigationTabContainer.vue";
import { Culture, Color } from "@/enums";
import { computed } from "vue";

import { IconSize } from "@/components/common/icon/Icon.types";
import { testIds } from "@/utils/testing";
import { interpolate } from "@/dictionary";
import texts from "@/utils/texts";
import Icon from "@/components/common/icon/Icon.vue";
import settings from "@/store/context/settings.context";

const props = defineProps<{
  localizations: Culture[];
}>();

const emit = defineEmits<{
  add: [values: Culture];
  remove: [values: Culture];
}>();

const model = defineModel<Culture>();

const defaultCulture = settings.mainLanguage.locale.value as Culture;

const defaultLocale = computed(() =>
  props.localizations.find((l) => l === defaultCulture),
);

if (!defaultLocale.value) {
  add(defaultCulture);
}

const notAddedCultures = computed(() =>
  settings.availableLanguages
    .map((language) => language.locale.value as Culture)
    .filter(
      (culture) => !props.localizations.find((locale) => locale === culture),
    ),
);

function add(culture: Culture) {
  emit("add", culture);

  model.value = culture;
}

function remove(culture: Culture) {
  emit("remove", culture);

  model.value = defaultCulture;
}

if (!defaultLocale.value) {
  add(defaultCulture);
}

if (!model.value) {
  model.value = defaultCulture;
}
</script>
