<template>
  <div class="flex items-center gap-8">
    <TextInputWithCopy :modelValue="regLink.uri" class="flex-1" />
    <DropDown
      data-testid="actions"
      :label="texts.actions.download"
      :origin="DropdownOrigin.TopRight"
      :loading="isDownloading"
    >
      <DropdownItem
        :label="textsRegLinks.downloadLinksExcel.downloadPreselectedButton"
        icon="table"
        @click="downloadPreselectedRegistrationLinksExcelFile(regLink.id)"
      ></DropdownItem>
      <DropdownItem
        :label="textsRegLinks.downloadLinksExcel.downloadPrefilteredButton"
        icon="table"
        @click="downloadPrefilteredRegistrationLinksExcelFile(regLink.id)"
      ></DropdownItem>
      <DropdownItem
        :label="textsRegLinks.downloadLinksExcel.downloadOnSiteButton"
        icon="table"
        @click="downloadOnSiteRegistrationLinksExcelFile(regLink.id)"
      ></DropdownItem>
      <DropdownItem
        :label="
          textsRegLinks.downloadLinksExcel
            .downloadPrefilteredStudyProgramLocationsButton
        "
        icon="table"
        @click="
          downloadPrefilteredStudyProgramLocationRegistrationLinksExcelFile(
            regLink.id,
          )
        "
      ></DropdownItem>
    </DropDown>
  </div>
</template>

<script setup lang="ts">
import DropDown from "@/components/common/dropdown/Dropdown.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import {
  downloadOnSiteRegistrationLinksExcel,
  downloadPrefilteredRegistrationLinksExcel,
  downloadPreselectedRegistrationLinksExcel,
} from "@/services/registrationLink.service";
import { ref } from "vue";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import TextInputWithCopy from "@/components/common/text-input/TextInputWithCopy.vue";
import { RegistrationLinkDTO } from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { downloadFileBySimulatingClickOnTemporaryAnchorTag } from "@/utils/downloadFile";

defineProps<{ regLink: RegistrationLinkDTO }>();

const textsRegLinks = texts.navigationItems.organize.activity.registrationLinks;

const isDownloading = ref(false);
const downloadPreselectedRegistrationLinksExcelFile = async (id: string) => {
  try {
    isDownloading.value = true;
    await downloadPreselectedRegistrationLinksExcel(id);
    Notify.success(textsRegLinks.downloadLinksExcel.success);
  } catch (err) {
    Notify.failure(textsRegLinks.downloadLinksExcel.failure);
    throw err;
  } finally {
    isDownloading.value = false;
  }
};

const downloadPrefilteredRegistrationLinksExcelFile = async (id: string) => {
  try {
    isDownloading.value = true;
    await downloadPrefilteredRegistrationLinksExcel(id);
    Notify.success(textsRegLinks.downloadLinksExcel.success);
  } catch (err) {
    Notify.failure(textsRegLinks.downloadLinksExcel.failure);
    throw err;
  } finally {
    isDownloading.value = false;
  }
};

const downloadOnSiteRegistrationLinksExcelFile = async (id: string) => {
  try {
    isDownloading.value = true;
    await downloadOnSiteRegistrationLinksExcel(id);
    Notify.success(textsRegLinks.downloadLinksExcel.success);
  } catch (err) {
    Notify.failure(textsRegLinks.downloadLinksExcel.failure);
    throw err;
  } finally {
    isDownloading.value = false;
  }
};

const downloadPrefilteredStudyProgramLocationRegistrationLinksExcelFile =
  async (id: string) => {
    try {
      isDownloading.value = true;
      const response =
        await eduConfigurationServiceClient.exportPrefilteredStudyProgramLocationsRegistrationLinks(
          id,
        );

      downloadFileBySimulatingClickOnTemporaryAnchorTag(
        response.data,
        response.fileName!,
      );
      Notify.success(textsRegLinks.downloadLinksExcel.success);
    } catch (err) {
      Notify.failure(textsRegLinks.downloadLinksExcel.failure);
      throw err;
    } finally {
      isDownloading.value = false;
    }
  };
</script>
