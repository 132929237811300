import { Culture } from "@/enums";
import { SessionMode } from "@/models/session";
import {
  CreateSessionDto,
  IUpdateSessionDto,
  SessionDto,
  UpdateSessionDto,
} from "@/lib/eduConfigurationServiceClient";
import { DateTime } from "luxon";

export interface SessionFormValues {
  studyProgramIds?: string[];
  groupId?: string;
  registrationLinkIds?: string[];
  startDateTime: DateTime;
  endDateTime: DateTime;
  online?: boolean;
  onlineSessionUrl?: string;
  locationId?: string;
  room?: string;
  contactName?: string;
  capacity?: number;
  typeId?: string;
  externalReference?: string;
  sequenceNumber?: number;
  registrationFromDateTime?: DateTime;
  registrationUntilDateTime?: DateTime;
  localizations: SessionFormValuesLocalization[];
}

interface SessionFormValuesLocalization {
  locale: Culture;
  name: string;
  descriptionForRegistrationForm?: string;
  descriptionForRegistrationConfirmation?: string;
}

export const convertToFormValues = (session: SessionDto): SessionFormValues => {
  return {
    studyProgramIds: session.studyProgramIds,
    groupId: session.groupId,
    registrationLinkIds: session.registrationLinkIds,
    online: session.mode === SessionMode.Online,
    onlineSessionUrl: session.onlineSessionUrl,
    startDateTime: session.startDateTime,
    endDateTime: session.endDateTime,
    locationId: session.locationId,
    room: session.room,
    contactName: session.contactName,
    capacity: session.capacity,
    typeId: session.typeId,
    externalReference: session.externalReference,
    sequenceNumber: session.sequenceNumber,
    registrationFromDateTime: session.registerFromDateTime,
    registrationUntilDateTime: session.registerUntilDateTime,
    localizations: session.localizations as SessionFormValuesLocalization[],
  };
};

export const convertToDto = (
  values: SessionFormValues,
): UpdateSessionDto | CreateSessionDto => {
  return new UpdateSessionDtoWithOffsetDates({
    localizations: values.localizations.filter((loc) => loc.name),
    groupId: values.groupId,
    studyProgramIds: values.studyProgramIds,
    registrationLinkIds: values.registrationLinkIds,
    mode: values.online ? SessionMode.Online : SessionMode.Physical,
    onlineSessionUrl: values.onlineSessionUrl,
    startDateTime: values.startDateTime,
    endDateTime: values.endDateTime,
    locationId: values.locationId,
    room: values.room,
    contactName: values.contactName,
    capacity: values.capacity,
    typeId: values.typeId,
    externalReference: values.externalReference,
    sequenceNumber: values.sequenceNumber,
    registerFromDateTime: values.registrationFromDateTime,
    registerUntilDateTime: values.registrationUntilDateTime,
  });
};

// This extends the UpdateSessionDto to add the offset dates
// This is needed because the Forms client expects the offset dates
// instead of the UTC dates.
// Needs to be fixed when timezones are implemented.
class UpdateSessionDtoWithOffsetDates extends UpdateSessionDto {
  constructor(data?: IUpdateSessionDto) {
    super(data);
  }

  toJSON(data?: Record<string, unknown>) {
    return {
      ...super.toJSON(data),
      startDateTime: this.startDateTime?.toISO(),
      endDateTime: this.endDateTime.toISO(),
      registerFromDateTime: this.registerFromDateTime?.toISO(),
      registerUntilDateTime: this.registerUntilDateTime?.toISO(),
    };
  }
}
