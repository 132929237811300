<template>
  <DropdownWrapper :origin="DropdownOrigin.TopRight">
    <template v-slot:button>
      <ButtonGroupMenuButton
        :color="Color.Gray"
        :size="ButtonSize.sm"
        flat
        round
        >{{ label }}</ButtonGroupMenuButton
      >
    </template>
    <template v-slot:items>
      <DropdownItem
        v-for="item in items"
        :key="item.value"
        @click="modelValueInternal = item.value"
        >{{ item.label }}</DropdownItem
      >
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts">
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import {
  Color,
  EmailTemplateType,
  MailingType,
  MailTemplateType,
} from "@/enums";
import texts from "@/utils/texts";
import { ButtonSize } from "@/components/common/button/Button.types";
import { computed } from "vue";
import { MailTemplate } from "@/models/template";

const props = defineProps<{
  modelValue: MailTemplateType | undefined;
  templates: MailTemplate[];
}>();

const modelValueInternal = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

const emit = defineEmits<{
  "update:modelValue": [value: MailTemplateType | undefined];
}>();

const label = computed(
  () => items.find((item) => item.value === modelValueInternal.value)?.label,
);

const itemTypes: Array<{ label: string; value: MailTemplateType | undefined }> =
  [
    {
      label: texts.enums.mailingType[MailingType.Activity],
      value: MailingType.Activity,
    },
    {
      label: texts.enums.mailingType[MailingType.ActivityInvite],
      value: MailingType.ActivityInvite,
    },
    {
      label: texts.enums.mailingType[MailingType.ActivityReminder],
      value: MailingType.ActivityReminder,
    },
    {
      label: texts.enums.mailingType[MailingType.ActivityVisitedSurvey],
      value: MailingType.ActivityVisitedSurvey,
    },
    {
      label:
        texts.enums.emailTemplateType[
          EmailTemplateType.RegistrationConfirmation
        ],
      value: EmailTemplateType.RegistrationConfirmation,
    },
    {
      label: texts.enums.emailTemplateType[EmailTemplateType.BrochureRequest],
      value: EmailTemplateType.BrochureRequest,
    },
    {
      label: texts.enums.emailTemplateType[EmailTemplateType.BrochureMailRoom],
      value: EmailTemplateType.BrochureMailRoom,
    },
    {
      label:
        texts.enums.emailTemplateType[
          EmailTemplateType.RegistrationNotification
        ],
      value: EmailTemplateType.RegistrationNotification,
    },
    {
      label:
        texts.enums.emailTemplateType[
          EmailTemplateType.RegistrationCanceledNotification
        ],
      value: EmailTemplateType.RegistrationCanceledNotification,
    },
    {
      label:
        texts.enums.emailTemplateType[EmailTemplateType.KeepMeInformedRequest],
      value: EmailTemplateType.KeepMeInformedRequest,
    },
    {
      label:
        texts.enums.emailTemplateType[
          EmailTemplateType.ApplicationConfirmation
        ],
      value: EmailTemplateType.ApplicationConfirmation,
    },
    {
      label:
        texts.enums.emailTemplateType[
          EmailTemplateType.ActivityCapacityFullNotification
        ],
      value: EmailTemplateType.ActivityCapacityFullNotification,
    },
  ];
const amountOfTemplatesWithTypes = (type: MailTemplateType) =>
  props.templates.filter((template) => template.type === type).length;

itemTypes.sort((a, b) => a.label.localeCompare(b.label));
itemTypes.unshift({
  label: texts.navigationItems.manage.settings.templates.typeFilterAll,
  value: undefined,
});
const items = itemTypes.filter((item) => {
  if (item.value === undefined) {
    return true;
  }
  return amountOfTemplatesWithTypes(item.value) > 0;
});
</script>
