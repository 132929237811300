import { Culture } from "@/enums";
import { LocalizedLanguage } from "@/lib/eduConfigurationServiceClient";
import {
  ActivityLocationCreateDTO,
  ActivityLocationExtended,
  ActivityLocationUpdateDTO,
} from "@/models/activityLocation";

export interface ActivityLocationFormValues {
  localizations: ActivityLocationFormValuesLocalization[];
  informationUrl?: string;
}

export type ActivityLocationFormValuesLocalization = {
  locale: Culture;
  name: string;
};

export const convertToFormValues = (
  activityLocation: ActivityLocationExtended,
  availableLanguages: LocalizedLanguage[],
): ActivityLocationFormValues => {
  const localizations = availableLanguages.map((available) => {
    const locale = activityLocation.localizations.find(
      (loc) => loc.locale === available.locale.value,
    );
    return {
      locale: available.locale.value as Culture,
      name: locale?.name ?? "",
    };
  });

  return {
    localizations,
    informationUrl: activityLocation.informationUrl,
  };
};

export const convertToCreateDto = (
  values: ActivityLocationFormValues,
): ActivityLocationCreateDTO => {
  const localizations = values.localizations.filter((loc) => !!loc.name);

  return {
    localizations,
    informationUrl: values.informationUrl,
  };
};

export const convertToUpdateDto = (
  id: string,
  values: ActivityLocationFormValues,
): ActivityLocationUpdateDTO => {
  const localizations = values.localizations.filter((loc) => !!loc.name);

  return {
    id: id,
    localizations,
    informationUrl: values.informationUrl,
  };
};
