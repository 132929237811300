import { ref } from "vue";
import {} from "@/lib/eduConfigurationServiceClient";
import { MailingTypeGroup } from "@/components/mailing-type-picker/MailingTypePicker.types";
import { toMailingTypeGroups } from "@/views/mailings/MainPage.types";
import store from "@/store";
import { Culture, MailingType as MailingTypeEnum } from "@/enums";
import { useAsyncState } from "@/components/common/async/Async.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { loadCriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import settings from "@/store/context/settings.context";

export const getDashboardSupportedMailingTypes = (): MailingTypeGroup[] => {
  const supportedMailingTypes = [
    MailingTypeEnum.General,
    MailingTypeEnum.ActivityInvite,
  ];

  const culture = store.getters["cultureStore/active"] as Culture;

  return toMailingTypeGroups(
    settings.mailingTypes.filter((item) =>
      supportedMailingTypes.includes(item.mailingType),
    ),
    culture,
  );
};

const { response: dashboardSelection, handler: loadDefaultSelection } =
  useAsyncState(() =>
    eduConfigurationServiceClient.getDefaultDashboardSelectionDefinition(),
  );

const { response: context, handler: loadContext } = useAsyncState(() =>
  loadCriterionFieldContext(),
);

const useDashboardSelectionForMailing = ref(false);

export const useDashboardSelection = () => {
  return {
    dashboardSelection,
    loadDefaultDashboardSelection: loadDefaultSelection,
    useDashboardSelectionForMailing,
    context,
    loadContext,
  };
};
