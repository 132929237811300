<template>
  <div
    class="mb-5 grid grid-cols-1 gap-1 sm:grid-cols-3 sm:items-center sm:gap-x-4"
  >
    <label :for="formFieldId" class="block text-sm font-medium text-gray-700">{{
      label
    }}</label>
    <DateTimeComponent
      :id="formFieldId"
      v-model="value"
      :name="formFieldId"
      :valid="!errorMessage"
      :disabled="disabled"
      :class="[{ 'col-span-2': fullWidth }]"
      :min="min"
      :max="max"
    ></DateTimeComponent>
    <div
      v-if="errorMessage"
      class="text-sm text-alert-500 sm:col-start-2"
      data-testid="error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import DateTimeComponent from "./DateTime.vue";
import { useField } from "vee-validate";
import { DateTime } from "luxon";

const props = withDefaults(
  defineProps<{
    formFieldId: string;
    label?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    min?: DateTime;
    max?: DateTime;
  }>(),
  {
    label: undefined,
    disabled: false,
    fullWidth: false,
    min: undefined,
    max: undefined,
  },
);

const { errorMessage, value } = useField<DateTime | undefined>(
  props.formFieldId,
);
</script>
