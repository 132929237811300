<template>
  <SlideOver
    v-model:visible="visibleInternal"
    data-testid="create-slide-over"
    :title
    :subTitle="description"
  >
    <Loader v-if="loadingForm" />
    <FormStylingSettingsForm
      v-else
      :formUri
      :pageSettings="formStyling.data.value"
      :pageFallbackSettings="fallbackFormStyling.data.value"
      :gtmSettings="gtmSettings.data.value"
      :gtmFallbackSettings="fallbackGtmSettings.data.value"
      @submit="handleSubmit"
    >
      <ButtonSubmit :loading="actionLoading"></ButtonSubmit>
      <ButtonCancel @click="cancelForm"></ButtonCancel>
    </FormStylingSettingsForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import texts from "@/utils/texts";
import { FileParameter } from "@/lib/formsServiceClient";
import Notify from "@/utils/notify";
import Loader from "@/components/common/loader/Loader.vue";
import {
  FormStylingFormValues,
  convertFormValuesToAnalyticDto,
  convertFormValuesToStyleDto,
} from "@/views/settings/forms/FormStylingSettingsForm.types";
import FormStylingSettingsForm from "@/views/settings/forms/FormStylingSettingsForm.vue";
import {
  deleteAnalytic,
  deleteStyle,
  putAnalytic,
  putStyle,
  uploadBanner,
  uploadFavicon,
  useAnalytic,
  useFormStyling,
} from "@/views/settings/forms/FormExtensions";

const props = defineProps<{
  visible: boolean;
  formUri?: string;
  title: string;
  description: string;
}>();

const emit = defineEmits(["update:visible", "create:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const actionLoading = ref<boolean>(false);
const loadingForm = ref<boolean>(true);

const gtmSettings = useAnalytic(props.formUri);
const fallbackGtmSettings = useAnalytic();
const formStyling = useFormStyling(props.formUri);
const fallbackFormStyling = useFormStyling();

const initialize = async () => {
  loadingForm.value = true;

  await Promise.all([gtmSettings.load(), formStyling.load()]);

  if (props.formUri) {
    await fallbackGtmSettings.load();
    await fallbackFormStyling.load();
  }

  loadingForm.value = false;
};

initialize();

const handleSubmit = async (values: FormStylingFormValues) => {
  try {
    actionLoading.value = true;
    if (values.useDefaultGTM) {
      await deleteAnalytic(props.formUri);
    } else {
      await putAnalytic(convertFormValuesToAnalyticDto(values), props.formUri);
    }

    if (values.useDefaultStyle) {
      await deleteStyle(props.formUri);
    } else {
      if (values.bannerFile && values.bannerFile.length > 0) {
        const bannerFile = values.bannerFile[0];
        const fileParameter: FileParameter = {
          data: bannerFile,
          fileName: bannerFile.name,
        };
        const bannerUrl = await uploadBanner(fileParameter, props.formUri);
        values.bannerUrl = bannerUrl;
      }

      if (values.faviconFile && values.faviconFile.length > 0) {
        const faviconFile = values.faviconFile[0];
        const fileParameter: FileParameter = {
          data: faviconFile,
          fileName: faviconFile.name,
        };
        const faviconUrl = await uploadFavicon(fileParameter, props.formUri);
        values.faviconUrl = faviconUrl;
      }

      await putStyle(convertFormValuesToStyleDto(values), props.formUri);
    }

    emit("create:succeeded");
    initialize();

    visibleInternal.value = false;

    Notify.success(texts.notifications.edit.success);
  } catch (error) {
    Notify.failure(texts.notifications.edit.failure);
    throw error;
  } finally {
    actionLoading.value = false;
  }
};

const cancelForm = () => {
  visibleInternal.value = false;
};
</script>
