<template>
  <FormLayout @submit="submitForm">
    <FormFieldArrayHelper
      v-slot="{ field, key }"
      :fieldId="fieldId"
      :fields="localizations"
    >
      <FormFieldTextInput
        :id="`${key}.name`"
        :label="`${texts.models.activityLocation.name} (${
          texts.enums.culture[field.locale]
        })`"
        :data-testid="`name_${field.locale}`"
      />
    </FormFieldArrayHelper>
    <FormFieldTextInput
      id="informationUrl"
      :label="texts.models.activityLocation.informationUrl"
      data-testid="informationUrl"
      :type="TextInputType.URL"
    />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import texts from "@/utils/texts";
import { ActivityLocationFormValues } from "@/views/settings/organization/activity-locations/ActivityLocationForm.types";
import { useForm } from "vee-validate";
import * as yup from "yup";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import { Culture } from "@/enums";
import { useLocalizations } from "@/models/helpers/localizable";
import FormLayout from "@/components/common/form/FormLayout.vue";

const props = defineProps<{
  formValues?: ActivityLocationFormValues;
}>();

const emit = defineEmits<{
  (e: "submit", values: ActivityLocationFormValues): void;
}>();

const validationSchema = yup.object({
  informationUrl: yup.string().url(),
  localizations: yup.array().of(
    yup.object({
      locale: yup.mixed<Culture>().required(),
      name: yup.string().requiredWhenMainLocale(),
    }),
  ),
});

const form = useForm<ActivityLocationFormValues>({ validationSchema });

const { fieldId, localizations } = useLocalizations(
  props.formValues?.localizations,
);

const submitForm = form.handleSubmit((values) => emit("submit", values));

if (props.formValues) form.setValues(props.formValues);
</script>
