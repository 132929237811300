<template>
  <template
    v-if="
      showStudyProgramExplainer &&
      filters.studyProgramIds &&
      filters.studyProgramIds.length > 0
    "
  >
    <p>
      <span class="font-bold">{{ texts.generic.and.toUpperCase() }}</span
      >{{
        ` ${texts.navigationItems.mailing.steps.selectionDefinition.showedInterestIn}`
      }}
      <span
        v-for="item in studyProgramLabels"
        :key="item"
        class="ml-2 rounded bg-gray-100 p-1"
      >
        {{ item }}
      </span>
    </p>
  </template>
  <template v-if="showDateRangeExplainer && filters.dateRange">
    <p>
      <span class="font-bold">{{ texts.generic.and.toUpperCase() }}</span
      >{{
        ` ${texts.navigationItems.mailing.steps.selectionDefinition.inPeriod}`
      }}
      <span class="rounded bg-gray-100 p-1">
        {{ filters.dateRange.from.toLocaleString(DateTime.DATE_SHORT) }} /
        {{ filters.dateRange.to.toLocaleString(DateTime.DATE_SHORT) }}
      </span>
    </p>
  </template>
</template>

<script setup lang="ts">
import {
  ISelectionFiltersDto,
  SelectionCriterionDtoType,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { computed } from "vue";
import { CriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";

const props = defineProps<{
  filters: ISelectionFiltersDto;
  criterionType: SelectionCriterionDtoType;
  context: CriterionFieldContext;
}>();

const showStudyProgramExplainer = computed(() =>
  [
    SelectionCriterionDtoType.ShowedInterest,
    SelectionCriterionDtoType.IsNotRegisteredForActivity,
    SelectionCriterionDtoType.HasNotVisitedActivity,
    SelectionCriterionDtoType.IsRegisteredForActivity,
    SelectionCriterionDtoType.ThirdPartyProspectSource,
    SelectionCriterionDtoType.ShowedInterest,
  ].includes(props.criterionType),
);

const showDateRangeExplainer = computed(() =>
  [
    SelectionCriterionDtoType.ShowedInterest,
    SelectionCriterionDtoType.IsInterestedInStudyProgram,
    SelectionCriterionDtoType.IsNotRegisteredForActivity,
    SelectionCriterionDtoType.HasNotVisitedActivity,
    SelectionCriterionDtoType.IsRegisteredForActivity,
    SelectionCriterionDtoType.ThirdPartyProspectSource,
    SelectionCriterionDtoType.ShowedInterest,
  ].includes(props.criterionType),
);

const studyProgramLabels = computed(() =>
  props.filters.studyProgramIds.map(
    (id) => props.context.studyPrograms.find((sp) => sp.id === id)?.displayName,
  ),
);
</script>
