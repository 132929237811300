<template>
  <Page
    :loading="loading"
    :error="error"
    class="mb-8 grid grid-cols-1 gap-8 md:grid-cols-12"
  >
    <div class="md:col-span-3">
      <ActivityFilter
        v-if="studyPrograms && activityTypes && activities"
        :activities="activities"
        :activityTypes="activityTypes"
        :studyPrograms="studyPrograms"
        @filter="(event) => (filteredActivities = event)"
      />
    </div>
    <div class="flex flex-col gap-8 md:col-span-9">
      <div
        class="top-0 flex flex-col items-start gap-5 text-left md:flex-row md:justify-between"
      >
        <MonthPicker v-model:dateTime="selectedDateTime" grouped></MonthPicker>
        <ButtonAdd v-if="canWrite" @click="goToCreateActivityPage" />
      </div>
      <Async :loading="loadingActivities">
        <ActivityItemSection
          v-if="filteredActivities"
          :activities="filteredActivities"
        >
          <template v-slot="{ activity }">
            <ActivityItem :activity="activity" />
          </template>
        </ActivityItemSection>
      </Async>
    </div>
  </Page>
</template>

<script setup lang="ts">
import store from "@/store";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import Page from "@/components/common/page/Page.vue";
import Activity from "@/models/activity";
import { getActivities } from "@/services/activity.service";
import { computed, ref, watch } from "vue";
import logger from "@/plugins/logger";
import Async from "@/components/common/loader/Async.vue";
import ActivityItemSection from "./components/ActivityItemSection.vue";
import ActivityItem from "./components/ActivityItem.vue";
import ActivityFilter from "./components/ActivityFilter.vue";
import ActivityType from "@/models/activityType";
import { getActivityTypes } from "@/services/activityTypes.service";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/routeNames";
import settings from "@/store/context/settings.context";
import { DateTime } from "luxon";
import MonthPicker from "@/components/common/monthpicker/MonthPicker.vue";
import { hasPermissions } from "@/router/helpers/userCanGoTo";

const selectedDateTime = ref<DateTime>(
  store.getters["activityStore/selectedDateTime"],
);

const monthEarliestDate = computed(() =>
  selectedDateTime.value.startOf("month").startOf("day"),
);

const monthLatestDate = computed(() =>
  selectedDateTime.value.endOf("month").endOf("day"),
);

const canWrite = hasPermissions([Permission.ActivitiesWrite]);

const loading = ref(true);
const error = ref(false);

const activities = ref<Activity[]>();
const filteredActivities = ref<Activity[]>();
const loadingActivities = ref(false);

watch(selectedDateTime, async () => {
  getActivitiesForMonth();
  store.commit("activityStore/setSelectedDateTime", selectedDateTime.value);
});

const getActivitiesForMonth = async () => {
  loadingActivities.value = true;
  try {
    const activityDtos = await getActivities(null, true, null, {
      minEndDateTime: monthEarliestDate.value.toISO() ?? undefined,
      maxStartDateTime: monthLatestDate.value.toISO() ?? undefined,
    });
    activities.value = activityDtos.map((dto) => new Activity(dto));
  } catch (e) {
    error.value = true;
    logger.error(e);
  }
  loadingActivities.value = false;
};

const activityTypes = ref<ActivityType[]>();
const studyPrograms = settings.studyPrograms;

const initialize = async () => {
  try {
    activityTypes.value = await getActivityTypes();
    await getActivitiesForMonth();
  } catch (e) {
    error.value = true;
    logger.error(e);
  }
  loading.value = false;
};
initialize();

const router = useRouter();
const goToCreateActivityPage = () => {
  router.push({ name: RouteNames.ACTIVITY_EDIT });
};
</script>
