<template>
  <form class="flex flex-col gap-8" @submit="submitForm">
    <LocalizationTabs
      v-model="activeCulture"
      :localizations="cultures"
      @add="addLocale"
      @remove="removeLocale"
    />
    <FormFieldTextInput
      v-for="culture in cultures"
      v-show="activeCulture === culture"
      :id="`localizations.${culture}.name`"
      :key="culture"
      :label="texts.models.activity.name"
      :data-testid="`name_${culture}`"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldWysiwyg
      v-for="culture in cultures"
      v-show="activeCulture === culture"
      :id="`localizations.${culture}.prospectAppDescription`"
      :key="culture"
      :label="texts.navigationItems.organize.activity.form.description"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <SectionDivider />
    <FormFieldSelect
      id="typeId"
      :label="texts.navigationItems.organize.activity.form.type"
      :items="selectItems"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldDateTime
      id="startDateTime"
      :label="texts.navigationItems.organize.activity.form.startDateTime"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldDateTime
      id="endDateTime"
      :label="texts.navigationItems.organize.activity.form.endDateTime"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldToggle
      id="isOnline"
      :label="texts.navigationItems.organize.activity.form.isOnline"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldTextInput
      v-if="form.values.isOnline"
      id="onlineUrl"
      :label="texts.navigationItems.organize.activity.form.onlineUrl"
      :type="TextInputType.URL"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldTextInput
      id="capacity"
      :label="texts.navigationItems.organize.activity.form.capacity"
      :type="TextInputType.NUMBER"
      :displayMode="FormFieldDisplayMode.Row"
    />

    <SectionDivider />
    <slot name="actions" />
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import * as yup from "yup";

import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import SectionDivider from "@/components/common/section/SectionDivider.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";

import { Culture } from "@/enums";
import texts from "@/utils/texts";
import { ActivitySettingsFormValues } from "./ActivitySettings.types";
import settings from "@/store/context/settings.context";
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";
import { DateTime } from "luxon";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import store from "@/store/index";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";

const defaultCulture = store.getters["cultureStore/mainCulture"] as Culture;

const props = defineProps<{
  formValues?: ActivitySettingsFormValues;
}>();

const emit = defineEmits<{
  submit: [values: ActivitySettingsFormValues];
}>();

const cultures = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

// Form configuration
const validationSchema = yup.object({
  localizations: yup.object(
    settings.availableLanguages.reduce(
      (acc, language) => ({
        ...acc,
        [language.locale.value as Culture]: yup.lazy((value) =>
          value
            ? yup.object({
                locale: yup.mixed<Culture>().required(),
                name: yup.string().max(256).required(),
                prospectAppDescription: yup.string().max(1024),
              })
            : yup.mixed(),
        ),
      }),
      {},
    ),
  ),
  startDateTime: yup.mixed<DateTime>().required(),
  endDateTime: yup
    .mixed<DateTime>()
    .required()
    .isLaterThan(
      "startDateTime",
      texts.validation.endDateTimeMinimumStartDateTime,
    ),
  typeId: yup.string().required(),
  isOnline: yup.boolean().required().default(false),
  onlineUrl: yup.string().when("isOnline", {
    is: true,
    then: (schema) => schema.url().required(),
  }),
  capacity: yup.number(),
  isVisibleForProspects: yup.boolean().required().default(false),
});

const form = useForm<ActivitySettingsFormValues>({
  validationSchema,
});

const selectItems = settings.activityTypes.map((at) => ({
  label: at.name,
  value: at.id,
}));

const activeCulture = ref(Culture.NL);

function addLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    addToLocalizations(form.values.localizations, culture, {
      locale: culture,
      name: "",
      prospectAppDescription: "",
    }),
  );
}

function removeLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    removeFromLocalizations(form.values.localizations, culture),
  );
}

function addToLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
  value: T,
) {
  return { ...localizations, [culture]: value };
}

function removeFromLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
) {
  const newDict = { ...localizations };
  delete newDict[culture];
  return newDict;
}

const submitForm = form.handleSubmit((values) => {
  emit("submit", values);
});

watch(
  () => props.formValues,
  (values) => {
    if (values) {
      form.setValues(values);
    }
  },
  { immediate: true },
);
</script>
