<template>
  <button
    type="button"
    class="'font-normal' flex items-center gap-1 text-nowrap hover:opacity-80 disabled:opacity-60"
    :disabled
  >
    <div class="flex items-center gap-2">
      <Icon
        v-if="leadingIcon"
        :icon="leadingIcon"
        :size="iconSize"
        :color="color"
      ></Icon>
      <a
        :class="[
          {
            'text-xs': size === ButtonSize.xs,
            'text-sm': size === ButtonSize.sm,
            'text-base': size === ButtonSize.md,
            'text-lg': size === ButtonSize.lg,
            'text-xl': size === ButtonSize.xl,
          },
          getTextColor(color),
        ]"
        role="button"
        tabindex="0"
        >{{ label }}</a
      >
      <slot></slot>
      <Icon
        v-if="trailingIcon"
        :icon="trailingIcon"
        :size="iconSize"
        :color="color"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import { IconNames, IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { computed } from "vue";
import { getTextColor } from "@/utils/color";
import { Color } from "@/enums";

const props = defineProps<{
  leadingIcon?: IconNames;
  trailingIcon?: IconNames;
  disabled?: boolean;
  label: string;
  size?: ButtonSize;
  color?: Color;
}>();

const iconSize = computed(() => {
  switch (props.size) {
    case ButtonSize.xs:
      return IconSize.xs;
    case ButtonSize.sm:
      return IconSize.sm;
    case ButtonSize.md:
      return IconSize.md;
    case ButtonSize.lg:
      return IconSize.lg;
    case ButtonSize.xl:
      return IconSize.xl;
    default:
      return IconSize.md;
  }
});
</script>
