import {
  ActivityLocationCreateDTO,
  ActivityLocationDTO,
  ActivityLocationExtendedDTO,
  ActivityLocationUpdateDTO,
} from "@/models/activityLocation";
import HttpClient from "@/utils/httpClient";

const serviceUrl = "activities/locations";

export const getActivityLocations = async (): Promise<
  ActivityLocationDTO[]
> => {
  return await HttpClient.educonfig
    .get<ActivityLocationDTO[]>(serviceUrl)
    .then((response) => response.data);
};

export const getActivityLocation = async (
  activityLocationId: string,
): Promise<ActivityLocationExtendedDTO> => {
  return await HttpClient.educonfig
    .get<ActivityLocationExtendedDTO>(`${serviceUrl}/${activityLocationId}`)
    .then((response) => response.data);
};

export const createActivityLocation = async (
  activityLocation: ActivityLocationCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(serviceUrl, activityLocation);
};

export const updateActivityLocation = async (
  activityLocation: ActivityLocationUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `${serviceUrl}/${activityLocation.id}`,
    activityLocation,
  );
};

export const deleteActivityLocation = async (
  activityLocationId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(
    `${serviceUrl}/${activityLocationId}`,
  );
};
