<template>
  <SlideOver
    v-model:visible="visible"
    :title="texts.navigationItems.dashboard.selection.title"
  >
    <div class="h-full">
      <SectionFlex v-if="selectionDefinition" class="px-4 pb-6 sm:px-6">
        <SelectionDefinition
          v-model:modelValue="selectionDefinition"
          :context
          :exclude="[SelectionCriterionDtoType.IsRegisteredForSession]"
        >
          <SelectionCountForDashboard :definition="selectionDefinition" />
        </SelectionDefinition>
        <template v-slot:footer>
          <div class="flex gap-2">
            <ButtonCancel @click="visible = false">
              {{ texts.navigationItems.dashboard.selection.backToDashboard }}
            </ButtonCancel>
          </div>
        </template>
      </SectionFlex>
    </div>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import SectionFlex from "@/components/common/section/SectionFlex.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { CriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import SelectionDefinition from "@/components/selection/SelectionDefinition.vue";
import {
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import SelectionCountForDashboard from "@/views/dashboard/components/SelectionCountForDashboard.vue";

defineProps<{ context: CriterionFieldContext }>();

const selectionDefinition = defineModel<SelectionDefinitionDto>(
  "selectionDefinition",
  {},
);
const visible = defineModel<boolean>("visible", { required: true });
</script>
