<template>
  <FormLayout @submit="submitForm">
    <SectionHeading
      :divider="false"
      :title="textsRegLinks.edit.slideOver.detailsSection.title"
      :description="textsRegLinks.edit.slideOver.detailsSection.description"
    />

    <RegistrationLinkEditActions :regLink="regLink" />

    <Divider />

    <SectionHeading
      :divider="false"
      :title="textsRegLinks.edit.slideOver.formSection.title"
      :description="textsRegLinks.edit.slideOver.formSection.description"
    />

    <LocalizationTabs
      v-model="activeCulture"
      :localizations="cultures"
      @add="addLocale"
      @remove="removeLocale"
    />
    <FormFieldTextInput
      v-for="culture in cultures"
      v-show="activeCulture === culture"
      :id="`localizations.${culture}.name`"
      :key="culture"
      :label="texts.models.registrationLink.name"
      :data-testid="`name_${culture}`"
    />

    <FormFieldToggle
      id="isVisibleForProspects"
      :label="
        texts.navigationItems.organize.activity.form.isVisibleForProspects
      "
    />

    <div class="flex flex-col gap-1">
      <FormFieldSelect
        id="form-readonly"
        :label="texts.models.registrationLink.form"
        :items="
          settings.forms
            .filter((f) => f.id === props.regLink.formId)
            .map((f) => ({
              label: f.name,
            }))
        "
        disabled
      />
      <RouterLinkAuth
        data-testid="editFormLink"
        :to="{
          name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
          params: { id: regLink.formId },
        }"
        ><span
          class="text-sm text-emerald-500 underline hover:text-emerald-700"
          >{{ textsRegLinks.edit.slideOver.formSection.editForm }}</span
        ></RouterLinkAuth
      >
    </div>

    <div class="flex flex-col gap-1">
      <FormFieldSelect
        id="confirmationEmailTemplateId"
        :label="texts.models.registrationLink.confirmationEmailTemplate"
        :items="
          settings.emailTemplates.map((et) => ({
            label: et.name,
            value: et.id,
          }))
        "
        allowUnset
      />
      <RouterLinkAuth
        v-if="form.values.confirmationEmailTemplateId"
        :to="{
          name: RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE,
          params: { id: form.values.confirmationEmailTemplateId },
        }"
        ><span
          class="text-sm text-emerald-500 underline hover:text-emerald-700"
          >{{
            textsRegLinks.edit.slideOver.formSection.editEmailTemplate
          }}</span
        ></RouterLinkAuth
      >
    </div>

    <div class="flex items-center gap-2">
      <FormFieldDateTime
        id="registrationStartDateTime"
        :label="texts.models.registrationLink.registrationStartDateTime"
        class="flex-1"
      />
      <FormFieldDateTime
        id="registrationEndDateTime"
        :label="texts.models.registrationLink.registrationEndDateTime"
        class="flex-1"
      />
    </div>

    <template v-slot:actions>
      <slot
        ><ButtonSubmit v-if="!deleting" :loading="editLoading" />
        <ButtonCancel v-if="!deleting" @click="emit('update:cancel')" />
        <ButtonDelete v-if="!deleting" @click="deleting = true" />
        <SlideOverDeleteConfirm
          v-if="deleting"
          :loading="deleteLoading"
          @confirm="submitDelete"
          @cancel="deleting = false"
        ></SlideOverDeleteConfirm
      ></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Culture } from "@/enums";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import {
  RegistrationLinkEditFormValues,
  convertToEditFormValues,
  convertToUpdateDTO,
} from "./RegistrationLinkForm.types";
import { useForm } from "vee-validate";
import * as yup from "yup";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import Notify from "@/utils/notify";
import { RouteNamesSettings } from "@/router/routeNames";
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import Divider from "@/components/common/divider/Divider.vue";
import texts from "@/utils/texts";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import settings from "@/store/context/settings.context";
import RegistrationLinkEditActions from "./RegistrationLinkEditActions.vue";
import { DateTime } from "luxon";
import { RegistrationLinkDTO } from "@/lib/eduConfigurationServiceClient";
import FormLayout from "@/components/common/form/FormLayout.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import SlideOverDeleteConfirm from "@/components/common/slideover/SlideOverDeleteConfirm.vue";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import store from "@/store/index";

const defaultCulture = store.getters["cultureStore/mainCulture"] as Culture;

const props = defineProps<{ regLink: RegistrationLinkDTO }>();

const emit = defineEmits<{
  "update:cancel": [];
  "update:success": [];
  "delete:success": [];
}>();

const cultures = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

const textsRegLinks = texts.navigationItems.organize.activity.registrationLinks;

// POC TODO: Typing of useForm
const form = useForm({
  validationSchema: yup.object({
    localizations: yup.object(
      settings.availableLanguages.reduce(
        (acc, language) => ({
          ...acc,
          [language.locale.value as Culture]: yup.lazy((value) =>
            value
              ? yup.object({
                  locale: yup.mixed<Culture>().required(),
                  name: yup.string().max(256).required(),
                  prospectAppDescription: yup.string().max(1024),
                })
              : yup.mixed(),
          ),
        }),
        {},
      ),
    ),
    confirmationEmailTemplateId: yup.string(),
    registrationStartDateTime: yup.mixed<DateTime>(),
    registrationEndDateTime: yup
      .mixed<DateTime>()
      .isLaterThan(
        "registrationStartDateTime",
        texts.validation.endDateTimeMinimumStartDateTime,
      ),
  }),
});

const activeCulture = ref(Culture.NL);

watch(
  props.regLink,
  (newRegLink) => {
    const values = convertToEditFormValues(newRegLink, activeCulture.value);
    form.setValues(values);
  },
  { immediate: true },
);

function addLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    addToLocalizations(form.values.localizations, culture, {
      locale: culture,
      name: "",
      prospectAppDescription: "",
    }),
  );
}

function removeLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    removeFromLocalizations(form.values.localizations, culture),
  );
}

function addToLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
  value: T,
) {
  return { ...localizations, [culture]: value };
}

function removeFromLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
) {
  const newDict = { ...localizations };
  delete newDict[culture];
  return newDict;
}

// Edit
const editLoading = ref(false);
const submitForm = form.handleSubmit((values) => {
  editLoading.value = true;

  const dto = convertToUpdateDTO(
    props.regLink.id,
    values as RegistrationLinkEditFormValues,
  );

  eduConfigurationServiceClient
    .updateRegistrationLink(props.regLink.activityId, props.regLink.id, dto)
    .then(() => {
      Notify.success(textsRegLinks.edit.success);
      emit("update:success");
    })
    .catch((e) => {
      Notify.failure(textsRegLinks.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      editLoading.value = false;
    });
});

// Delete
const deleting = ref<boolean>(false);
const deleteLoading = ref<boolean>(false);

const submitDelete = async () => {
  try {
    deleteLoading.value = true;

    await eduConfigurationServiceClient.deleteRegistrationLink(
      props.regLink.activityId,
      props.regLink.id,
    );

    Notify.success(textsRegLinks.delete.success);
    emit("delete:success");
  } catch (e) {
    Notify.failure(textsRegLinks.delete.failure);
    throw e;
  } finally {
    deleteLoading.value = false;
  }
};
</script>
