<template>
  <div v-if="editContext" class="flex w-full flex-col items-start gap-3">
    <TextInputInline
      v-model="localization.label"
      v-model:focus="focusLabel"
      :placeholder="`${formTexts.page.titleCaps} ${page.sequenceNumber + 1}`"
      css="text-lg font-semibold text-deepteal-500 placeholder:text-deepteal-500"
    />

    <BrowserWindow>
      <template v-slot:banner>
        <div class="bg-gray-100">
          <img
            v-if="style?.bannerUrl"
            :src="style.bannerUrl"
            alt="Form banner"
          />
          <img v-else :src="hippoLogo" alt="Form banner" class="w-96 py-8" />
        </div>
      </template>
      <template v-slot:actions>
        <div class="flex items-center gap-3">
          <button
            v-if="!isFirstPage"
            :title="texts.actions.moveUp"
            @click="emit('move:page:up', page)"
          >
            <Icon
              icon="arrow_upward"
              :size="IconSize.xs"
              :color="Color.Black"
              class="transition-all hover:stroke-info-500"
            />
          </button>
          <button
            v-if="!isLastPage"
            :title="texts.actions.moveDown"
            @click="emit('move:page:down', page)"
          >
            <Icon
              icon="arrow_downward"
              :size="IconSize.xs"
              :color="Color.Black"
              class="transition-all hover:stroke-info-500"
            />
          </button>
          <button
            :title="texts.actions.delete"
            @click="emit('delete:page', page)"
          >
            <Icon
              icon="delete"
              :size="IconSize.xs"
              :color="Color.Black"
              class="transition-all hover:stroke-alert-500"
            />
          </button>
        </div>
      </template>
      <div class="flex w-full max-w-2xl flex-col items-stretch gap-4">
        <WysiwygEditor
          :key="currentLocale"
          v-model="localization.header"
          :placeholder="formTexts.page.headerPlaceholder"
          :inlineCss="style?.sass"
        />
        <div class="flex flex-col gap-4">
          <template v-for="(question, index) in questions" :key="question.id">
            <FormTemplateQuestion
              v-show="question.id"
              class="w-full"
              :currentLocale="currentLocale"
              :question="question"
              :isFirstQuestion="index === 0"
              :isLastQuestion="index === questions.length - 1"
              :conditionalQuestions="
                pageInternal.getConditionalQuestions(question)
              "
              @update:question:settings="
                pageInternal.updateQuestionSettings(
                  $event,
                  question,
                  editContext.questions,
                )
              "
              @delete:question="
                pageInternal.deleteQuestion($event.sequenceNumber)
              "
              @move:question:up="
                pageInternal.moveQuestionUp($event.sequenceNumber)
              "
              @move:question:down="
                pageInternal.moveQuestionDown($event.sequenceNumber)
              "
            ></FormTemplateQuestion>
          </template>
          <ButtonSettings
            icon="add"
            class="my-6"
            @click="slideOverQuestionCreateOpen = true"
          >
            {{ interpolate(texts.actions.addNew, formTexts.question.title) }}
          </ButtonSettings>
        </div>
        <WysiwygEditor
          :key="currentLocale"
          v-model="localization.footer"
          :placeholder="formTexts.page.footerPlaceholder"
          :inlineCss="style?.sass"
        />
      </div>
      <div class="flex w-full items-center justify-end gap-3 px-3 py-3">
        <div class="flex gap-3">
          <FormTemplateButton
            v-if="!isFirstPage"
            :color="style?.sass.smtColorPrimary"
            class="hover:cursor-pointer"
            @click="emit('scroll:page:up', page)"
          >
            {{ navigationTexts.previous }}
          </FormTemplateButton>
          <FormTemplateButton
            v-if="!isLastPage"
            :color="style?.sass.smtColorPrimary"
            class="hover:cursor-pointer"
            @click="emit('scroll:page:down', page)"
          >
            {{
              nextPageLabel
                ? interpolate(navigationTexts.nextTo, nextPageLabel)
                : navigationTexts.next
            }}
          </FormTemplateButton>
          <FormTemplateButton
            v-if="isLastPage"
            :color="style?.sass.smtColorPrimary"
            class="hover:cursor-pointer"
            @click="emit('scroll:confirm')"
            >{{ navigationTexts.complete }}</FormTemplateButton
          >
        </div>
      </div>
      <template v-slot:footer>
        <WysiwygEditor
          :key="currentLocale"
          v-model="localization.pageFooter"
          :placeholder="formTexts.page.pageFooterPlaceholder"
          :inlineCss="style?.sass"
        />
      </template>
    </BrowserWindow>
    <SlideOverCreateFormTemplateQuestion
      v-model:visible="slideOverQuestionCreateOpen"
      :currentLocale="currentLocale"
      :questionOptions="questionOptions"
      @create:question="(formValues) => createQuestion(formValues)"
    />
  </div>
</template>

<script setup lang="ts">
import {
  FormPageDTO,
  FormPageLocalizationDTO,
  IStyleDTOWithSassVariables,
} from "@/lib/formsServiceClient";
import { computed, inject, ref, watch } from "vue";
import FormTemplateQuestion from "@/views/settings/forms/components/form-template-editor/FormTemplateQuestion.vue";
import "@/views/settings/forms/FormExtensions";
import WysiwygEditor from "@/components/common/wysiwyg/WysiwygEditor.vue";
import { FormTemplateEditContextKey } from "@/views/settings/forms/FormExtensions";
import BrowserWindow from "@/views/settings/forms/components/form-template-editor/BrowserWindow.vue";
import FormTemplateButton from "@/views/settings/forms/components/form-template-editor/FormTemplateButton.vue";

import { IconSize } from "@/components/common/icon/Icon.types";
import hippoLogo from "@/assets/images/logo-hippo.svg";
import Icon from "@/components/common/icon/Icon.vue";
import TextInputInline from "@/components/common/text-input/TextInputInline.vue";
import texts from "@/utils/texts";
import dictionary, { interpolate } from "@/dictionary";
import { Color, Culture, FormType } from "@/enums";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import SlideOverCreateFormTemplateQuestion from "@/views/settings/forms/components/form-template-editor/SlideOverCreateFormTemplateQuestion.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import { QuestionSettingsFormValues } from "@/views/settings/forms/components/form-template-editor/FormTemplateQuestionSettingsForm.types";

const props = defineProps<{
  currentLocale: Culture;
  page: FormPageDTO;
  style?: IStyleDTOWithSassVariables;
  nextPageLabel?: string;
  isLastPage?: boolean;
  isFirstPage?: boolean;
}>();

const emit = defineEmits<{
  "update:page": [page: FormPageDTO];
  "delete:page": [page: FormPageDTO];
  "move:page:up": [page: FormPageDTO];
  "move:page:down": [page: FormPageDTO];
  "scroll:page:up": [page: FormPageDTO];
  "scroll:page:down": [page: FormPageDTO];
  "scroll:confirm": [];
}>();

const formTexts = texts.navigationItems.manage.settings.formTemplates;
const navigationTexts = computed(
  () =>
    dictionary[props.currentLocale].navigationItems.manage.settings
      .formTemplates.navigation,
);

const createQuestion = (formValues: QuestionSettingsFormValues) => {
  if (!editContext) throw new Error("FormTemplateEditContextKey not found");
  pageInternal.value.createQuestion(formValues, editContext.questions);
  slideOverQuestionCreateOpen.value = false;
};

const editContext = inject(FormTemplateEditContextKey);
if (!editContext) throw new Error("FormTemplateEditContextKey not found");

const pageInternal = computed({
  get: () => props.page,
  set: (value: FormPageDTO) => {
    emit("update:page", value);
  },
});

const questions = computed(() => pageInternal.value.getQuestions());
const questionOptions = computed<SelectOption[]>(() => {
  return (
    editContext?.questions
      .filter((q) =>
        editContext.formType === FormType.ActivityVisitedSurvey
          ? q.isSurveyQuestion
          : !q.isSurveyQuestion,
      )
      .map((q) => {
        return {
          value: q.id,
          label:
            q.localizations.find((l) => l.locale.value === props.currentLocale)
              ?.label ?? "",
        } satisfies SelectOption;
      })
      .sort((a, b) => a.label.localeCompare(b.label)) ?? []
  );
});

const localization = computed({
  get: () =>
    pageInternal?.value.localizations.find(
      (l) => l.locale === props.currentLocale.toString(),
    ) as FormPageLocalizationDTO,
  set: (value: FormPageLocalizationDTO) => {
    const index = pageInternal.value.localizations.findIndex(
      (l) => l.locale === props.currentLocale,
    );
    pageInternal.value.localizations[index] = value;
  },
});

const focusLabel = ref(false);

watch(
  () => props.currentLocale,
  (locale) => {
    localization.value = props.page.localizations.find(
      (l) => l.locale === locale?.toString(),
    ) as FormPageLocalizationDTO;
  },
);

const slideOverQuestionCreateOpen = ref<boolean>(false);
</script>
