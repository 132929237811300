import { Form } from "@/models/form";
import { Culture } from "@/enums";
import { DateTime } from "luxon";
import {
  RegistrationLinkDTO,
  CreateRegistrationLinkDTO,
  CreateRegistrationLinkFormDTO,
  ICreateRegistrationLinkLocalizationDTO,
  UpdateRegistrationLinkDTO,
  IUpdateRegistrationLinkLocalizationDto,
  RegistrationLinkLocalizationDTO,
} from "@/lib/eduConfigurationServiceClient";

export interface RegistrationLinkCreateFormValues {
  localizations: Record<Culture, RegistrationLinkFormValuesLocalization>;
  formId: string;
  confirmationEmailTemplateId?: string;
  isVisibleForProspects: boolean;
}

export interface RegistrationLinkEditFormValues {
  localizations: Record<Culture, RegistrationLinkFormValuesLocalization>;
  confirmationEmailTemplateId?: string;
  registrationStartDateTime?: DateTime;
  registrationEndDateTime?: DateTime;
  isVisibleForProspects: boolean;
}

export interface RegistrationLinkFormValuesLocalization {
  locale: Culture;
  name: string;
}

export const convertToEditFormValues = (
  registrationLink: RegistrationLinkDTO,
  mainLanguage: Culture,
): RegistrationLinkEditFormValues => {
  const localizations =
    registrationLink.localizations !== undefined
      ? localizationArrayToObject(
          registrationLink.localizations.sort((a) =>
            a.locale === mainLanguage ? -1 : 1,
          ),
        )
      : ({
          [mainLanguage]: {
            locale: mainLanguage,
            name: "",
          },
        } as Record<Culture, RegistrationLinkFormValuesLocalization>);

  return {
    localizations: localizations,
    confirmationEmailTemplateId: registrationLink.confirmationEmailTemplateId,
    registrationStartDateTime: registrationLink.registrationStartDateTime,
    registrationEndDateTime: registrationLink.registrationEndDateTime,
    isVisibleForProspects: registrationLink.isVisibleForProspects,
  };
};

export const convertToCreateDTO = (
  values: RegistrationLinkCreateFormValues,
  forms: Form[],
): CreateRegistrationLinkDTO => {
  const form = forms.filter((f) => f.id === values.formId)[0];
  if (!form)
    throw new Error(
      `Could not create registration link, because no form with id ${values.formId} could be found`,
    );

  const formDto = new CreateRegistrationLinkFormDTO(form);
  const dto = new CreateRegistrationLinkDTO({
    localizations: createLocalizationObjectToArray(values.localizations),
    isVisibleForProspects: values.isVisibleForProspects,
    form: formDto,
    confirmationEmailTemplateId: values.confirmationEmailTemplateId,
    registrationStartDateTime: undefined,
    registrationEndDateTime: undefined,
  });

  return dto;
};

export function createLocalizationObjectToArray(
  localizations: Record<Culture, RegistrationLinkFormValuesLocalization>,
): ICreateRegistrationLinkLocalizationDTO[] {
  return Object.keys(localizations)
    .filter((key) => localizations[key as Culture]?.name)
    .map((key) => ({
      locale: key as Culture,
      name: localizations[key as Culture].name,
    }));
}

export const convertToUpdateDTO = (
  id: string,
  values: RegistrationLinkEditFormValues,
): UpdateRegistrationLinkDTO => {
  const dto = new UpdateRegistrationLinkDTO({
    id,
    localizations: updateLocalizationObjectToArray(values.localizations),
    isVisibleForProspects: values.isVisibleForProspects,
    confirmationEmailTemplateId: values.confirmationEmailTemplateId,
    registrationStartDateTime: values.registrationStartDateTime,
    registrationEndDateTime: values.registrationEndDateTime,
  });

  return dto;
};

export function updateLocalizationObjectToArray(
  localizations: Record<Culture, RegistrationLinkFormValuesLocalization>,
): IUpdateRegistrationLinkLocalizationDto[] {
  return Object.keys(localizations)
    .filter((key) => localizations[key as Culture]?.name)
    .map((key) => ({
      locale: key as Culture,
      name: localizations[key as Culture].name,
    }));
}

export function localizationArrayToObject(
  localizations: RegistrationLinkLocalizationDTO[],
) {
  return localizations.reduce(
    (cur, localization) => {
      const key = localization.locale as Culture;
      cur[key] = {
        locale: localization.locale as Culture,
        name: localization.name ?? "",
      };
      return cur;
    },
    {} as Record<Culture, RegistrationLinkFormValuesLocalization>,
  );
}
