<template>
  <section
    class="flex h-full w-80 flex-col gap-16 bg-white px-6 py-6 text-deepteal-500 print:hidden"
    :data-testid="testIds.activitySideBar.main"
  >
    <ActivitySidebarNavigationItem
      :to="{ name: RouteNames.ORGANIZE }"
      icon="arrow_back"
    >
      <span class="text-sm" :data-testid="testIds.backToOverview">{{
        navigationTexts.back
      }}</span>
    </ActivitySidebarNavigationItem>
    <TransitionFade>
      <header v-if="activity" class="flex flex-col gap-2">
        <span class="text-xs">
          {{ activityDateInterval }}
        </span>
        <h1 class="text-xl font-semibold">
          {{ activity.name }}
        </h1>
        <ActivityStatus
          v-if="activity.status"
          :status="activity.status"
        ></ActivityStatus>
      </header>
    </TransitionFade>
    <nav class="flex flex-col gap-6">
      <Collapsible v-show="canUseSetup" v-model:expanded="setupExpanded">
        <button
          class="flex w-full items-center justify-start gap-2 text-left text-base font-normal text-deepteal-500"
          :data-testId="testIds.activitySideBar.setup"
          @click="setupExpanded = !setupExpanded"
        >
          <Icon icon="tune" :color="Color.DeepTeal" :size="IconSize.sm"></Icon>
          <span>Setup</span>
          <Chevron :expanded="setupExpanded" />
        </button>
        <TransitionFade> </TransitionFade>
        <Icon
          v-if="!setupExpanded && setupCompleted"
          icon="check_circle"
          filled
          :color="Color.Emerald"
          :size="IconSize.sm"
        ></Icon>
        <template v-slot:expanded>
          <nav class="flex flex-col gap-4 pl-7">
            <ActivitySidebarNavigationItem
              :testId="testIds.activitySideBar.basicSettings"
              :to="{
                name: RouteNamesActivityOverview.SETTINGS,
                params: { id },
              }"
              :completed="!!activity"
              >{{ navigationTexts.settings }}
            </ActivitySidebarNavigationItem>
            <ActivitySidebarNavigationItem
              :to="{
                name: RouteNamesActivityOverview.STUDYPROGRAMS,
                params: { id },
              }"
              :disabled="!id"
              :completed="
                !!activity?.studyProgramIds &&
                activity.studyProgramIds.length > 0
              "
              >{{ navigationTexts.studyPrograms }}
            </ActivitySidebarNavigationItem>
            <ActivitySidebarNavigationItem
              :to="{
                name: RouteNamesActivityOverview.RegistrationLinks,
                params: { id },
              }"
              :disabled="!id"
              :completed="registrationLinks.length > 0"
              >{{ navigationTexts.registrationLinks }}
            </ActivitySidebarNavigationItem>
          </nav>
        </template>
      </Collapsible>
      <Divider v-show="canUseSetup" />
      <ActivitySidebarNavigationItem
        icon="calendar_month"
        :to="{
          name: RouteNamesActivityReports.OVERVIEW,
          params: { id },
        }"
        :disabled="!id"
      >
        <span>{{ navigationTexts.hub }}</span>
      </ActivitySidebarNavigationItem>
      <Divider />
      <ActivitySidebarNavigationItem
        :to="{
          name: RouteNamesActivityOverview.SESSIONS,
          params: { id },
        }"
        icon="dns"
        :disabled="!id"
        >{{ navigationTexts.sessions }}
      </ActivitySidebarNavigationItem>
      <ActivitySidebarNavigationItem
        :to="{
          name: RouteNamesActivityOverview.SMS,
          params: { id },
        }"
        icon="campaign"
        :disabled="!id"
        >{{ navigationTexts.sms }}
      </ActivitySidebarNavigationItem>
      <ActivitySidebarNavigationItem
        :to="{
          name: RouteNamesActivityOverview.SCAN_CONFIGURATIONS,
          params: { id },
        }"
        icon="qr_code_2"
        :disabled="!id"
        >{{ navigationTexts.scanConfigurations }}
      </ActivitySidebarNavigationItem>
      <ActivitySidebarNavigationItem
        :to="{
          name: RouteNamesActivityOverview.NOTIFICATION_SETTINGS,
          params: { id },
        }"
        icon="notifications"
        :disabled="!id"
      >
        {{ navigationTexts.notificationSettings }}
      </ActivitySidebarNavigationItem>
      <ActivitySidebarNavigationItem
        v-if="hasAffiliates"
        :to="{
          name: RouteNamesActivityOverview.AFFILIATE_LINKS,
          params: { id },
        }"
        icon="tenancy"
        :disabled="!id"
      >
        {{ navigationTexts.affiliateLinks }}
      </ActivitySidebarNavigationItem>
    </nav>
  </section>
</template>

<script setup lang="ts">
import { Permission } from "@/lib/eduConfigurationServiceClient";
import Divider from "@/components/common/divider/Divider.vue";
import {
  RouteNames,
  RouteNamesActivityOverview,
  RouteNamesActivityReports,
} from "@/router/routeNames";
import texts from "@/utils/texts";
import { computed, ref, watchEffect } from "vue";
import ActivityStatus from "@/views/organize/activities/components/ActivityStatus.vue";
import ActivitySidebarNavigationItem from "./ActivitySidebarNavigationItem.vue";
import { testIds } from "@/utils/testing";
import activityContext from "@/store/context/activity.context";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Chevron from "@/components/common/chevron/Chevron.vue";
import { useRoute } from "vue-router";
import { Color } from "@/enums";
import { DateTime, Interval } from "luxon";
import Icon from "@/components/common/icon/Icon.vue";
import { allAffiliates } from "@/views/organize/activities/activity/affiliate-links/AffiliateLink.types";
import { hasPermissions } from "@/router/helpers/userCanGoTo";

const navigationTexts = texts.navigationItems.organize.navigationTabs;

const id = computed(() => activityContext.activity?.id);
const activity = computed(() => activityContext.activity);
const registrationLinks = computed(() => activityContext.registrationLinks);

const activityDateInterval = computed(() =>
  activity.value
    ? Interval.fromDateTimes(
        activity.value.startDateTime,
        activity.value.endDateTime,
      ).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
    : undefined,
);

const hasAffiliates = ref<boolean>(true);
allAffiliates().then((affiliates) => {
  hasAffiliates.value = affiliates ? affiliates.length > 0 : false;
});

const setupExpanded = ref(false);
const setupCompleted = computed(() =>
  registrationLinks.value ? registrationLinks.value.length > 0 : undefined,
);

const canUseSetup = hasPermissions([Permission.ActivitiesWrite]);

const route = useRoute();
watchEffect(() => {
  const isSetupRoute = route.matched.some(
    (match) => RouteNames.ACTIVITY_OVERVIEW === match.name,
  );
  setupExpanded.value = isSetupRoute || setupCompleted.value === false;
});
</script>
