import {
  Culture,
  FormType as FormTypeEnum,
  MailingType as MailingTypeEnum,
} from "@/enums";
import TargetAudience from "@/models/targetAudience";
import { FromEmailAddress, ReplyToEmailAddress } from "@/models/email-address";
import { Form } from "@/models/form";
import {
  ConceptMailingCreateDTO,
  ConceptMailingUpdateDTO,
  MailingExtended,
  PlannedMailingCreateDTO,
  PlannedMailingUpdateDTO,
} from "@/models/mailing";
import { MailingType } from "@/models/mailingType";
import { RegistrationLink } from "@/models/registrationLink";
import { formsServiceClient } from "@/services/formsService.client.service";
import { getRegistrationLinks } from "@/services/registrationLink.service";
import { LocalizedQuestionWithDiscreteAnswers } from "@/lib/formsServiceClient";
import {
  LocalizedActivityDTO,
  LocalizedStudyProgramDTO,
  MailingSelectionDefinitionDto,
  PreEducationLevelDTO,
  RecruitmentYearDTO,
  SelectionCriterionDto,
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
  ThirdPartyProspectSourceDto,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { DateTime } from "luxon";
import { IEntityContentJson } from "@beefree.io/sdk/dist/types/bee";

export type ConceptMailingData = {
  type?: MailingTypeEnum;
  locale: Culture;
  name?: string;
  activity?: LocalizedActivityDTO;
  registrationLink?: RegistrationLink;
  form?: Form;
  subject?: string;
  fromName?: string;
  fromEmailAddress?: string;
  replyToEmailAddress?: string;
  mailingContent?: {
    configuration?: IEntityContentJson;
    content?: string;
  };
  selectionDefinition?: SelectionDefinitionDto;
  datetime?: DateTime;
};

export type CompleteMailingData = {
  type: MailingTypeEnum;
  locale: Culture;
  name: string;
  activity: LocalizedActivityDTO;
  registrationLink?: RegistrationLink;
  form?: Form;
  subject: string;
  fromName: string;
  fromEmailAddress: string;
  replyToEmailAddress: string;
  mailingContent: {
    configuration: IEntityContentJson;
    content: string;
  };
  selectionDefinition: SelectionDefinitionDto;
  datetime: DateTime;
};

export type MailingContextData = {
  id?: string; // Id of the mailing. Should not be here but reactivity is weird on the ConceptMailingData
  recruitmentYear: RecruitmentYearDTO;
  activities: LocalizedActivityDTO[];
  studyPrograms: LocalizedStudyProgramDTO[];
  fromEmailAddresses: FromEmailAddress[];
  replyToEmailAddresses: ReplyToEmailAddress[];
  mailingType: MailingType;
  surveyForms: Form[];
  targetAudiences: TargetAudience[];
  languages: Culture[];
  questionsWithAnswers: LocalizedQuestionWithDiscreteAnswers[];
  mailings: MailingSelectionDefinitionDto[];
  preEducationLevels: PreEducationLevelDTO[];
  thirdPartyProspectSources: ThirdPartyProspectSourceDto[];
  selectionDefinition?: SelectionDefinitionDto;
};

export const convertToMailingConceptData = async (
  mailing: MailingExtended,
  availableActivities: LocalizedActivityDTO[],
): Promise<ConceptMailingData> => {
  const activity = availableActivities.find(
    (act) => act.id === mailing.activityId,
  );

  let registrationLink;
  if (
    !!mailing.activityId &&
    mailing.type === MailingTypeEnum.ActivityInvite &&
    !!mailing.registrationLinkId
  ) {
    registrationLink = (await getRegistrationLinks(mailing.activityId)).filter(
      (registrationLink) => registrationLink.id === mailing.registrationLinkId,
    )[0];
  }

  let form;
  if (
    mailing.type === MailingTypeEnum.ActivityVisitedSurvey &&
    !!mailing.formId
  ) {
    form = (
      await formsServiceClient.getForms(FormTypeEnum.ActivityVisitedSurvey)
    )
      .map((dto) => new Form(dto))
      .filter((form) => form.id === mailing.formId)[0];
  }

  let selectionDefinition: SelectionDefinitionDto | undefined;
  if (mailing.selectionDefinitionId) {
    selectionDefinition =
      await eduConfigurationServiceClient.getSelectionDefinition(
        mailing.selectionDefinitionId,
      );
  }

  return {
    type: mailing.type,
    locale: mailing.locale,
    name: mailing.name,
    activity: activity,
    registrationLink: registrationLink,
    form: form,
    subject: mailing.subject,
    fromName: mailing.fromName,
    fromEmailAddress: mailing.fromEmailAddress,
    replyToEmailAddress: mailing.replyToEmailAddress,
    mailingContent: {
      configuration: mailing.mailingContent
        ? (JSON.parse(
            mailing.mailingContent.configuration,
          ) as IEntityContentJson)
        : undefined,
      content: mailing.mailingContent
        ? mailing.mailingContent.content
        : undefined,
    },
    selectionDefinition: selectionDefinition,
    datetime: mailing.plannedDateTime,
  };
};

export const convertToConceptMailingCreateDTO = (
  conceptData: ConceptMailingData,
  selectionDefinitionId?: string,
): ConceptMailingCreateDTO => {
  if (!conceptData.name || !conceptData.type)
    throw new Error(
      "Cannot convert to create-dto. name or type of mailing was not provided.",
    );

  return {
    name: conceptData.name,
    type: conceptData.type,
    locale: conceptData.locale,
    activityId: conceptData.activity?.id,
    registrationLinkId: conceptData.registrationLink?.id,
    formId: conceptData.form?.id,
    plannedDateTime: conceptData.datetime?.toISO() ?? undefined,
    fromEmailAddress: conceptData.fromEmailAddress,
    replyToEmailAddress: conceptData.replyToEmailAddress,
    fromName: conceptData.fromName,
    mailingContent:
      conceptData.mailingContent &&
      conceptData.mailingContent.configuration &&
      conceptData.mailingContent.content
        ? {
            configuration: JSON.stringify(
              conceptData.mailingContent.configuration,
            ),
            content: conceptData.mailingContent.content,
          }
        : undefined,
    subject: conceptData.subject,
    selectionDefinitionId,
  };
};

export const convertToConceptMailingUpdateDTO = (
  id: string,
  conceptData: ConceptMailingData,
  selectionDefinitionId?: string,
): ConceptMailingUpdateDTO => {
  return {
    ...convertToConceptMailingCreateDTO(conceptData, selectionDefinitionId),
    id,
  };
};

export const convertToPlannedMailingCreateDTO = (
  completeData: CompleteMailingData,
  selectionDefinitionId: string,
): PlannedMailingCreateDTO => {
  return {
    name: completeData.name,
    type: completeData.type,
    locale: completeData.locale,
    activityId: completeData.activity?.id,
    registrationLinkId: completeData.registrationLink?.id,
    formId: completeData.form?.id,
    plannedDateTime: completeData.datetime.toISO() ?? "invalid",
    fromEmailAddress: completeData.fromEmailAddress,
    replyToEmailAddress: completeData.replyToEmailAddress,
    fromName: completeData.fromName,
    mailingContent: {
      configuration: JSON.stringify(completeData.mailingContent.configuration),
      content: completeData.mailingContent.content,
    },
    subject: completeData.subject,
    selectionDefinitionId,
  };
};

export const convertToPlannedMailingUpdateDTO = (
  id: string,
  completeData: CompleteMailingData,
  selectionDefinitionId: string,
): PlannedMailingUpdateDTO => {
  return {
    ...convertToPlannedMailingCreateDTO(completeData, selectionDefinitionId),
    id,
  };
};

export function createNewDefinitionForMailingType(
  mailingType: MailingType,
  existingDefinition?: SelectionDefinitionDto,
  mailingActivityId?: string,
) {
  const requiredCriteria =
    mailingType.selectionDefinitionAttributes?.requiredCriteria;

  // If there already are criteria on this definition,
  // we won't (re-)add the non-required criteria
  const nonRequiredCriteria =
    mailingType.selectionDefinitionAttributes?.nonRequiredCriteria;

  const isActivityCriterion = (type: SelectionCriterionDtoType) =>
    type === SelectionCriterionDtoType.HasVisitedActivity ||
    type === SelectionCriterionDtoType.HasNotVisitedActivity ||
    type === SelectionCriterionDtoType.NotSubmittedSurveyForVisitedActivity ||
    type === SelectionCriterionDtoType.IsRegisteredForActivity ||
    type === SelectionCriterionDtoType.IsNotRegisteredForActivity;

  const newDefinition = new SelectionDefinitionDto({
    responseType: mailingType.selectionDefinitionAttributes.responseType,
    criteria: [],
    filters: {
      studyProgramIds: [],
      dateRange: undefined,
    },
  });

  // Sanitize existing criteria by removing any existing required criteria with the same type as the new required criteria
  // Set the remaining criteria on not readOnly, so the user can edit them or remove them at will
  if (existingDefinition) {
    newDefinition.criteria = existingDefinition.criteria
      .filter(
        (criterion) =>
          !(criterion.isReadOnly && requiredCriteria.includes(criterion.type)),
      )
      .map(
        (criterion) =>
          new SelectionCriterionDto({ ...criterion, isReadOnly: false }),
      );
  }

  // (Re)-add the required criteria
  newDefinition.criteria = newDefinition.criteria.concat(
    requiredCriteria.map((type) => {
      const requiredCriterion = new SelectionCriterionDto({
        type: type,
        isReadOnly: true,
      });
      if (mailingActivityId && isActivityCriterion(type))
        requiredCriterion.activityIds = [mailingActivityId];
      return requiredCriterion;
    }),
  );

  // Only add non-required criteria if there are no criteria on the given definition
  if (!existingDefinition || existingDefinition.criteria.length === 0)
    newDefinition.criteria = newDefinition.criteria.concat(
      nonRequiredCriteria.map((type) => {
        const nonRequiredCriterion = new SelectionCriterionDto({
          type: type,
          isReadOnly: false,
        });
        if (mailingActivityId && isActivityCriterion(type))
          nonRequiredCriterion.activityIds = [mailingActivityId];
        return nonRequiredCriterion;
      }),
    );

  newDefinition.filters = existingDefinition?.filters ?? newDefinition.filters;

  return newDefinition;
}

export async function saveOrUpdateSelectionDefinition(
  selectionDefinition: SelectionDefinitionDto,
  selectionDefinitionId?: string,
) {
  if (selectionDefinitionId) {
    return await eduConfigurationServiceClient.updateSelectionDefinition(
      selectionDefinitionId,
      selectionDefinition,
    );
  } else {
    return await eduConfigurationServiceClient.saveSelectionDefinition(
      selectionDefinition,
    );
  }
}
