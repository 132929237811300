<template>
  <form class="flex h-full flex-col gap-y-2" @submit="submitForm">
    <Card
      :class="[
        'flex flex-col items-stretch gap-2 text-sm text-deepteal-600 transition-all',
      ]"
      :color="criterion.isReadOnly ? Color.DeepTeal : Color.White"
      :flat="criterion.isReadOnly"
    >
      <div class="flex items-center gap-2">
        <CriterionTypeSelectField
          :criterion="criterion"
          :context="context"
          :edit="edit"
          :exclude="exclude"
          :filters="filters"
          class="flex-1"
        />
      </div>
      <CriterionTypedComponent
        :criterion="criterion"
        :context="context"
        :edit="edit"
        :exclude="exclude"
      />
      <SelectionFilterCriterionExplainer
        v-if="!edit"
        :criterionType="criterion.type"
        :filters
        :context
      />
      <div class="flex gap-2"><slot name="actions" /></div>
    </Card>
  </form>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useForm } from "vee-validate";
import {
  CriterionFieldProps,
  typeToComponentMapper,
} from "@/components/selection/SelectionDefinition.helpers";
import CriterionTypeSelectField from "@/components/selection/type/CriterionTypeSelectField.vue";
import Card from "../common/card/Card.vue";
import { Color } from "@/enums";
import { ISelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import SelectionFilterCriterionExplainer from "@/components/selection/SelectionFilterCriterionExplainer.vue";

const props = defineProps<CriterionFieldProps>();

const emit = defineEmits<{
  (e: "submit", values: ISelectionCriterionDto): void;
}>();

const form = useForm<ISelectionCriterionDto>();

const CriterionTypedComponent = computed(() =>
  typeToComponentMapper.get(form.values.type),
);

form.setValues(props.criterion);

watch(
  () => props.edit,
  (edit) => {
    if (!edit) {
      form.resetForm();
      form.setValues(props.criterion);
    }
  },
);

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
