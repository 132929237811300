<template>
  <FormLayout @submit="submitForm">
    <div class="flex flex-col gap-2">
      <CheckboxField
        :formFieldId="forbidOverlappingSessionRegistrationsId"
        :title="
          texts.models.sessionProgram.forbidOverlappingSessionRegistrations
            .title
        "
        :description="
          texts.models.sessionProgram.forbidOverlappingSessionRegistrations
            .description
        "
      />
      <div class="max-w-lg pl-6">
        <Alert
          :content="
            texts.models.sessionProgram.forbidOverlappingSessionRegistrations
              .exactlyOverlappingDateTimesExplanation
          "
        />
      </div>
    </div>
    <CheckboxField
      :formFieldId="forbidMultipleSessionRegistrationsForStudyProgramId"
      :title="
        texts.models.sessionProgram
          .forbidMultipleSessionRegistrationsPerStudyProgram.title
      "
      :description="
        texts.models.sessionProgram
          .forbidMultipleSessionRegistrationsPerStudyProgram.description
      "
    />
    <div class="ml-7 flex flex-col items-start gap-1">
      <label
        :for="maximumSessionRegistrationsPerRegistrantId"
        class="block text-sm font-medium text-gray-700"
      >
        {{
          texts.models.sessionProgram.maximumSessionRegistrationsPerRegistrant
        }}
      </label>
      <InputField
        class="max-w-sm"
        :formFieldId="maximumSessionRegistrationsPerRegistrantId"
        :type="TextInputType.NUMBER"
        :min="0"
      />
    </div>
    <CheckboxField
      :formFieldId="allowGroupSizeId"
      :title="texts.models.sessionProgram.allowGroupSize.title"
      :description="texts.models.sessionProgram.allowGroupSize.description"
    />

    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import Alert from "@/components/common/alert/Alert.vue";
import { SessionProgramSettingsFormValues } from "@/views/organize/activities/activity/sessions/components/session-settings/SlideOverSessionSettingsForm.types";
import dictionary from "@/dictionary";
import { useStore } from "vuex";
import { CultureStore } from "@/store/modules/culture.store";
import { Culture } from "@/enums";
import CheckboxField from "@/components/common/checkbox/CheckboxField.vue";
import InputField from "@/components/common/text-input/InputField.vue";
import * as yup from "yup";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormLayout from "@/components/common/form/FormLayout.vue";

const store = useStore<CultureStore>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const props = defineProps<{
  formValues: SessionProgramSettingsFormValues;
}>();

const emit = defineEmits<{
  (e: "submit", values: SessionProgramSettingsFormValues): void;
}>();

const forbidOverlappingSessionRegistrationsId: keyof SessionProgramSettingsFormValues =
  "forbidOverlappingSessionRegistrations";
const forbidMultipleSessionRegistrationsForStudyProgramId: keyof SessionProgramSettingsFormValues =
  "forbidMultipleSessionRegistrationsForStudyProgram";
const maximumSessionRegistrationsPerRegistrantId: keyof SessionProgramSettingsFormValues =
  "maximumSessionRegistrationsPerRegistrant";
const allowGroupSizeId: keyof SessionProgramSettingsFormValues =
  "allowGroupSize";

const form = useForm<SessionProgramSettingsFormValues>({
  validationSchema: yup.object({
    forbidMultipleSessionRegistrationsForStudyProgram: yup.boolean().required(),
    forbidOverlappingSessionRegistrations: yup.boolean().required(),
    maximumSessionRegistrationsPerRegistrant: yup.number().positive(),
    allowGroupSize: yup.boolean().required(),
  }),
  initialValues: {
    forbidMultipleSessionRegistrationsForStudyProgram:
      props.formValues?.forbidMultipleSessionRegistrationsForStudyProgram,
    forbidOverlappingSessionRegistrations:
      props.formValues?.forbidOverlappingSessionRegistrations,
    maximumSessionRegistrationsPerRegistrant:
      props.formValues?.maximumSessionRegistrationsPerRegistrant,
    allowGroupSize: props.formValues?.allowGroupSize,
  },
});

const submitForm = async () => {
  await form.validate().then((result) => {
    if (result.valid) {
      emit("submit", form.values);
    }
  });
};
</script>
