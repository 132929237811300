<template>
  <TransitionFade>
    <div class="flex h-screen flex-col items-center justify-center gap-4">
      <img :src="logoHippo" alt="Vue logo" class="-mt-3 h-32 grayscale" />
      <h1 class="text-xl font-light">
        {{ texts.unavailable.title }}
      </h1>
      <h2 class="text-base font-light">
        {{ texts.unavailable.description }}
      </h2>
      <Button
        :color="Color.Gray"
        :disabled
        @click="router.replace({ name: RouteNames.LOGIN })"
        >{{ texts.actions.retry }}</Button
      >
    </div>
  </TransitionFade>
</template>

<script setup lang="ts">
import logoHippo from "@/assets/images/logo-hippo.svg";
import Button from "@/components/common/button/Button.vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { Color } from "@/enums";
import { RouteNames } from "@/router/routeNames";
import texts from "@/utils/texts";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const disabled = ref(true);
setTimeout(() => {
  disabled.value = false;
}, 2000);
</script>
