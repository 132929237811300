import { MailingType } from "@/enums";
import { SelectionDefinitionDto } from "@/lib/eduConfigurationServiceClient";
import { LocalizedFormDTO } from "@/lib/formsServiceClient";
import { MailingExtended } from "@/models/mailing";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { DateTime } from "luxon";
import { ConceptMailingData } from "../MailingWizard.types";
import settings from "@/store/context/settings.context";

export interface MailingDescriptionListData {
  type?: MailingType;
  localeName: string;
  name?: string;
  activityId?: string;
  activityName?: string;
  registrationLinkName?: string;
  formName?: string;
  subject?: string;
  fromName?: string;
  fromEmailAddress?: string;
  replyToEmailAddress?: string;
  mailingContent?: string;
  datetime?: DateTime;
  selectionDefinition?: SelectionDefinitionDto;
}

export interface MailingDescriptionListProps {
  data: MailingDescriptionListData;
}

export async function getMailingDescriptionListDataFromMailing(
  mailing: MailingExtended,
  forms?: LocalizedFormDTO[],
): Promise<MailingDescriptionListData> {
  let selectionDefinition: SelectionDefinitionDto | undefined;

  if (mailing.selectionDefinitionId) {
    selectionDefinition =
      await eduConfigurationServiceClient.getSelectionDefinition(
        mailing.selectionDefinitionId,
      );
  }

  const language = settings.availableLanguages.find(
    (lang) => lang.locale.value === mailing.locale,
  )?.name;
  if (!language) {
    throw new Error(`Language with locale ${mailing.locale} not found`);
  }

  return {
    type: mailing.type,
    localeName: language,
    name: mailing.name,
    activityId: mailing.activityId,
    activityName: mailing.activityName,
    registrationLinkName: mailing.registrationLinkName,
    formName: forms?.find((form) => form.id === mailing.formId)?.label,
    subject: mailing.subject,
    fromEmailAddress: mailing.fromEmailAddress,
    fromName: mailing.fromName,
    mailingContent: mailing.mailingContent?.content,
    replyToEmailAddress: mailing.replyToEmailAddress,
    datetime: mailing.plannedDateTime,
    selectionDefinition: selectionDefinition,
  };
}

export function getMailingDescriptionListDataFromConcept(
  concept: ConceptMailingData,
): MailingDescriptionListData {
  const language = settings.availableLanguages.find(
    (lang) => lang.locale.value === concept.locale,
  )?.name;
  if (!language) {
    throw new Error(`Language with locale ${concept.locale} not found`);
  }

  return {
    type: concept.type,
    localeName: language,
    name: concept.name,
    activityId: concept.activity ? concept.activity.id : undefined,
    activityName: concept.activity ? concept.activity.name : undefined,
    registrationLinkName: concept.registrationLink?.name,
    formName: concept.form?.label,
    subject: concept.subject,
    fromName: concept.fromName,
    fromEmailAddress: concept.fromEmailAddress,
    replyToEmailAddress: concept.replyToEmailAddress,
    mailingContent: concept.mailingContent?.content,
    datetime: concept.datetime,
    selectionDefinition: concept.selectionDefinition,
  };
}
