import { SessionReportDTO } from "@/models/activityReport";
import {
  ISelectionDefinitionDto,
  ISelectionDto,
  ProspectDepartmentCountsDTO,
  ProspectStatsDTO,
  ProspectStudyProgramInterestCountsDTO,
  SelectionDto,
} from "@/lib/eduConfigurationServiceClient";
import { RegistrationsAtDateDTO } from "@/models/registrationsAtDate";
import { downloadFileResponse } from "@/utils/downloadFile";
import HttpClient from "@/utils/httpClient";
import { DateTime } from "luxon";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";

export const getProspectStats = async (
  selection: ISelectionDto,
): Promise<ProspectStatsDTO> => {
  return await eduConfigurationServiceClient.getProspectStats(
    new SelectionDto(selection),
  );
};

export const getSessionTop10ForActivity = async (
  activityId: string,
): Promise<SessionReportDTO[]> => {
  return await HttpClient.educonfig
    .get<SessionReportDTO[]>(`activities/${activityId}/sessions/stats`)
    .then((response) => response.data);
};

export const getRegistrationsOverTimeForActivity = async (
  activityId: string,
  startDateTime?: DateTime,
  endDateTime?: DateTime,
): Promise<RegistrationsAtDateDTO[]> => {
  return await HttpClient.educonfig
    .get<RegistrationsAtDateDTO[]>(`activity/${activityId}/registrations`, {
      params: {
        startDateTime: startDateTime ? startDateTime.toISO() : undefined,
        endDateTime: endDateTime ? endDateTime.toISO() : undefined,
        period: "Daily",
      },
    })
    .then((response) => response.data);
};

export const downloadRegistrantsCSV = async (
  activityId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(`activity/${activityId}/registrants`, { responseType: "blob" })
    .then((response) => downloadFileResponse(response));
};

export const downloadVisitorsCSV = async (
  activityId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(`export/activities/${activityId}/visitors`, {
      responseType: "blob",
    })
    .then((response) => downloadFileResponse(response));
};

export const downloadVisitedSurveysExcel = async (
  activityId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(`activities/${activityId}/visitedsurveys/export`, {
      responseType: "blob",
    })
    .then((response) => downloadFileResponse(response));
};

export const getDepartmentProspectCounts = async (
  selection: ISelectionDefinitionDto,
  activityId?: string,
): Promise<ProspectDepartmentCountsDTO> => {
  return await eduConfigurationServiceClient.getProspectNumbersForDepartments(
    activityId,
    new SelectionDto({ ...selection }),
  );
};

export const getProspectStudyProgramInterestCounts = async (
  departmentId: string,
  selection: ISelectionDefinitionDto,
  activityId?: string,
): Promise<ProspectStudyProgramInterestCountsDTO> => {
  return await eduConfigurationServiceClient.getProspectNumbersForDepartmentInterests(
    departmentId,
    activityId,
    new SelectionDto({ ...selection }),
  );
};
