<template>
  <div class="flex w-full flex-col gap-3">
    <label class="text-lg font-semibold text-deepteal-500"
      >{{ formTexts.confirmationPage.title }}
    </label>

    <BrowserWindow>
      <template v-slot:banner>
        <div class="bg-gray-100">
          <img
            v-if="style?.bannerUrl"
            :src="style.bannerUrl"
            alt="Form banner"
          />
          <img v-else :src="hippoLogo" alt="Form banner" class="w-96 py-8" />
        </div>
      </template>
      <template v-slot>
        <div class="flex w-full max-w-2xl flex-col gap-4">
          <WysiwygEditor
            :key="currentLocalization.locale"
            v-model="currentLocalization.confirmationPage"
            :placeholder="formTexts.confirmationPage.confirmationPlaceholder"
            :inlineCss="style?.sass"
          />
        </div>
      </template>
    </BrowserWindow>

    <div class="flex flex-row">
      <div class="flex flex-1 flex-col gap-1">
        <label class="text-sm font-semibold text-deepteal-500"
          >{{ formTexts.confirmationPage.redirectTitle }}
        </label>
        <p class="text-sm text-gray-500">
          {{ formTexts.confirmationPage.redirectDescription }}
        </p>
      </div>
      <Toggle v-model="redirectUrlIsEnabled" />
    </div>

    <TextInput
      v-model="currentLocalization.redirectUrl"
      icon="captive_portal"
      :type="TextInputType.URL"
      :placeholder="formTexts.confirmationPage.redirectUrlPlaceholder"
      :class="{ 'text-gray-500': !redirectUrlIsEnabled }"
    />
  </div>
</template>

<script setup lang="ts">
import {
  FormLocalizationDTO,
  IStyleDTOWithSassVariables,
} from "@/lib/formsServiceClient";
import { computed, inject, ref, watch } from "vue";
import { FormTemplateEditContextKey } from "@/views/settings/forms/FormExtensions";
import WysiwygEditor from "@/components/common/wysiwyg/WysiwygEditor.vue";
import TextInput from "@/components/common/text-input/TextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import BrowserWindow from "@/views/settings/forms/components/form-template-editor/BrowserWindow.vue";
import hippoLogo from "@/assets/images/logo-hippo.svg";
import texts from "@/utils/texts";
import { Culture } from "@/enums";
import Toggle from "@/components/common/toggle/Toggle.vue";

const editContext = inject(FormTemplateEditContextKey);
if (!editContext) throw new Error("FormTemplateEditContextKey not found");

const props = defineProps<{
  currentLocale: Culture;
  modelValue: FormLocalizationDTO[];
  style?: IStyleDTOWithSassVariables;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: FormLocalizationDTO[]];
}>();

const formTexts = texts.navigationItems.manage.settings.formTemplates;

if (props.modelValue.length === 0) {
  throw new Error("Current localization not found during component mount");
}

const localizationsInternal = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  },
});

const currentLocalization = ref(
  localizationsInternal.value.find(
    (l) => l.locale === props.currentLocale?.toString(),
  ) as FormLocalizationDTO,
);

const redirectUrlIsEnabled = ref(
  !!currentLocalization.value.redirectUrl &&
    currentLocalization.value.redirectUrl.length > 0,
);

watch(
  () => currentLocalization.value.redirectUrl,
  () => {
    redirectUrlIsEnabled.value =
      !!currentLocalization.value.redirectUrl &&
      currentLocalization.value.redirectUrl.length > 0;
  },
);

watch(
  () => redirectUrlIsEnabled.value,
  (value) => {
    if (!value) {
      currentLocalization.value.redirectUrl = "";
    }
  },
);

watch(
  () => props.currentLocale,
  (locale) => {
    currentLocalization.value = localizationsInternal.value.find(
      (l) => l.locale === locale?.toString(),
    ) as FormLocalizationDTO;
  },
);
</script>
