<template>
  <Section>
    <PageHeading :title="texts.navigationItems.mailings.title" />
  </Section>
  <MailingTypePicker
    v-model:visible="mailingTypePickerVisible"
    :title="texts.navigationItems.mailings.mailingTypePicker.title"
    :description="texts.navigationItems.mailings.mailingTypePicker.description"
    :mailingTypeGroups="mailingTypeGroups"
  ></MailingTypePicker>
  <Page :loading="loading" :error="error" class="flex flex-row gap-4">
    <div class="grid flex-grow grid-cols-12 gap-12">
      <div class="col-span-3 flex flex-col gap-4">
        <MailingFilter
          :mailings="mailings"
          :mailingTypes="settings.mailingTypes.map((mt) => mt.mailingType)"
          :mailingStates="statuses"
          @filter="
            (value) => {
              splitMailings(value);
            }
          "
          @selectedTypes="
            (value) => {
              selectedMailingStates = value;
            }
          "
        ></MailingFilter>
      </div>
      <div class="col-span-9">
        <div class="mb-10">
          <div class="mb-auto mt-auto inline text-xl font-semibold">
            {{ mailingCount }} {{ texts.generic.results }}
          </div>
          <ButtonAdd
            v-show="canWrite"
            data-testid="new-mailing-button"
            class="float-right inline"
            :onClick="
              () => (mailingTypePickerVisible = !mailingTypePickerVisible)
            "
          />
        </div>
        <div class="flex flex-col gap-12">
          <List
            v-if="
              !selectedMailingStates ||
              (plannedMailings && plannedMailings.length > 0)
            "
            :title="texts.enums.mailingStatus.Planned"
          >
            <MailingItem
              v-for="mailing in plannedMailings"
              :key="mailing.id"
              :mailing="mailing"
            />
          </List>
          <List
            v-if="
              !selectedMailingStates ||
              (conceptMailings && conceptMailings.length > 0)
            "
            :title="texts.enums.mailingStatus.Concept"
          >
            <MailingItem
              v-for="mailing in conceptMailings"
              :key="mailing.id"
              :mailing="mailing"
            />
          </List>
          <List
            v-if="
              !selectedMailingStates ||
              (sentMailings && sentMailings.length > 0)
            "
            :title="texts.enums.mailingStatus.Sent"
          >
            <MailingItem
              v-for="mailing in sentMailings"
              :key="mailing.id"
              :mailing="mailing"
            />
          </List>
          <List
            v-if="
              !selectedMailingStates &&
              failedMailings &&
              failedMailings.length > 0
            "
            :title="texts.enums.mailingStatus.Failed"
          >
            <MailingItem
              v-for="mailing in failedMailings"
              :key="mailing.id"
              :mailing="mailing"
            />
          </List>
        </div>
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { Culture, MailingStatus } from "@/enums";
import { Mailing } from "@/models/mailing";
import { getMailings } from "@/services/mailings.service";
import { computed, ref } from "vue";

// Components
import Page from "@/components/common/page/Page.vue";
import logger from "@/plugins/logger";
import { useRoute } from "vue-router";
import List from "@/components/common/list/List.vue";
import MailingItem from "@/views/mailings/components/MailingItem.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import MailingTypePicker from "@/components/mailing-type-picker/MailingTypePicker.vue";
import { MailingTypeGroup } from "@/components/mailing-type-picker/MailingTypePicker.types";
import dictionary from "@/dictionary";
import { RootState } from "@/store";
import { useStore } from "vuex";
import { toMailingTypeGroups } from "./MainPage.types";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Section from "@/components/common/section/Section.vue";
import MailingFilter from "./components/MailingFilter.vue";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import settings from "@/store/context/settings.context";
import { Permission } from "@/lib/eduConfigurationServiceClient";

const route = useRoute();
// Store setup
const store = useStore<RootState>();

const canWrite = hasPermissions([Permission.MailingsWrite]);

const defaultStatuses = [
  MailingStatus.Planned,
  MailingStatus.Sent,
  MailingStatus.Failed,
  MailingStatus.Concept,
];

const plannedMailings = ref<Mailing[]>();
const sentMailings = ref<Mailing[]>();
const failedMailings = ref<Mailing[]>();
const conceptMailings = ref<Mailing[]>();

const mailings = ref<Mailing[]>([]);
const mailingCount = computed(() => {
  return (
    (plannedMailings.value?.length || 0) +
    (sentMailings.value?.length || 0) +
    (failedMailings.value?.length || 0) +
    (conceptMailings?.value?.length || 0)
  );
});

const determineLandingStatuses = (
  mailingStatus?: MailingStatus,
): MailingStatus[] => {
  if (!mailingStatus) {
    return defaultStatuses;
  } else if (defaultStatuses.includes(mailingStatus)) {
    return defaultStatuses;
  }
  return [mailingStatus];
};

const culture = store.getters["cultureStore/active"] as Culture;
const texts = dictionary[culture];

const mailingTypePickerVisible = ref(false);

const mailingTypeGroups: MailingTypeGroup[] = toMailingTypeGroups(
  settings.mailingTypes,
  culture,
);
// Page filters
const statuses = ref<MailingStatus[]>(
  determineLandingStatuses(route.params["statusFilter"] as MailingStatus),
);

const selectedMailingStates = ref<MailingStatus[] | undefined>();

// Loading & Error
const loading = ref<boolean>(true);
const error = ref<boolean>(false);

const plannedSortFn = (left: Mailing, right: Mailing) => {
  return left.plannedDateTime &&
    right.plannedDateTime &&
    left.plannedDateTime < right.plannedDateTime
    ? -1
    : 1;
};

const sentSortFn = (left: Mailing, right: Mailing) => {
  return left.sentDateTime &&
    right.sentDateTime &&
    left.sentDateTime < right.sentDateTime
    ? 1
    : -1;
};

const conceptSortFn = (left: Mailing, right: Mailing) => {
  return left.savedDateTime < right.savedDateTime ? 1 : -1;
};

const splitMailings = (input: Mailing[]) => {
  plannedMailings.value = input
    .filter(
      (mailing: Mailing) =>
        mailing.status === MailingStatus.Planned ||
        mailing.status === MailingStatus.InProgress,
    )
    .sort(plannedSortFn);
  sentMailings.value = input
    .filter((mailing: Mailing) => mailing.status === MailingStatus.Sent)
    .sort(sentSortFn);
  failedMailings.value = input
    .filter((mailing: Mailing) => mailing.status === MailingStatus.Failed)
    .sort(sentSortFn);
  conceptMailings.value = input
    .filter((mailing: Mailing) => mailing.status === MailingStatus.Concept)
    .sort(conceptSortFn);
};

getMailings()
  .then((res) => {
    mailings.value = res;
    splitMailings(res);
  })
  .catch((e) => {
    error.value = true;
    logger.log(e);
  })
  .finally(() => {
    loading.value = false;
  });
</script>
