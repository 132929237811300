<template>
  <form class="form-editor flex flex-col gap-4" @submit="onSubmit">
    <LocalizationTabs
      v-model="currentLocale"
      :localizations="currentLocales"
      @add="addLocalization"
      @remove="removeLocalization"
    />
    <PageHeading>
      <template
        v-for="locale in modelValueInternal.localizations"
        :key="locale.locale"
      >
        <FormFieldTextInputInline
          v-show="currentLocale === locale.locale"
          :id="`localizations.${locale.locale}.label`"
          v-model:focus="labelFocus"
          :placeholder="formTexts.newFormTemplate"
          required
        />
      </template>
      <Badge
        v-if="badgeLabel"
        :icon="formTypeIcons[formEditContext.formType]"
        :label="badgeLabel"
        :type="BadgeType.info"
        :class="['ml-4']"
      ></Badge>
      <template v-slot:actions>
        <span class="flex items-center text-alert-500">{{
          form.errors.value.localizations
        }}</span>
        <slot name="heading"></slot>
      </template>
    </PageHeading>
    <div
      class="flex w-full flex-col items-center rounded-md border-deepteal-100 p-6"
      :style="
        formStyling?.sass.smtColorBackground
          ? {
              backgroundColor: formStyling.sass.smtColorBackground,
              borderWidth: '1px',
            }
          : undefined
      "
    >
      <div class="flex w-full max-w-4xl flex-col items-start gap-6">
        <template v-if="modelValueInternal.pages.length > 0">
          <template v-for="(_, index) in modelValueInternal.pages" :key="index">
            <div :ref="createHandler(index)">
              <FormTemplatePage
                v-model:page="modelValueInternal.pages[index]"
                :currentLocale="currentLocale"
                :style="formStyling"
                :nextPageLabel="
                  modelValueInternal.pages[index + 1]?.localizations.find(
                    (localization: FormPageLocalizationDTO) =>
                      localization.locale === culture,
                  )?.label
                "
                :isFirstPage="index === 0"
                :isLastPage="index === modelValueInternal.pages.length - 1"
                @delete:page="
                  modelValueInternal.deletePage($event.sequenceNumber)
                "
                @move:page:up="
                  modelValueInternal.movePageUp($event.sequenceNumber)
                "
                @move:page:down="
                  modelValueInternal.movePageDown($event.sequenceNumber)
                "
                @scroll:page:up="scrollPageUp(index)"
                @scroll:page:down="scrollPageDown(index)"
                @scroll:confirm="scrollPageConfirm"
              ></FormTemplatePage>
            </div>
            <ButtonSettings
              icon="add"
              @click="modelValueInternal.addPage(index + 1)"
            >
              {{ interpolate(texts.actions.addNew, formTexts.page.title) }}
            </ButtonSettings>
          </template>
        </template>
        <template v-else>
          <ButtonSettings icon="add" @click="modelValueInternal.addPage(0)">
            {{ interpolate(texts.actions.addNew, formTexts.page.title) }}
          </ButtonSettings>
        </template>

        <div ref="confirmPageRef">
          <FormTemplateConfirmationPage
            v-if="modelValueInternal.localizations.length > 0"
            v-model="modelValueInternal.localizations"
            :currentLocale="currentLocale"
            :style="formStyling"
          />
        </div>
      </div>
    </div>

    <div class="mt-4 flex flex-row items-center justify-end gap-4">
      <slot name="actions"></slot>
    </div>
  </form>
</template>

<script setup lang="ts">
import { FormDTO, FormPageLocalizationDTO } from "@/lib/formsServiceClient";
import {
  FormTemplateEditContext,
  FormTemplateEditContextKey,
  useFormStyling,
} from "@/views/settings/forms/FormExtensions";
import { formsServiceClient } from "@/services/formsService.client.service";
import { Culture, FormType } from "@/enums";
import texts from "@/utils/texts";
import { useStore } from "vuex";
import { RootState } from "@/store";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import Badge from "@/components/common/badge/Badge.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import FormTemplatePage from "@/views/settings/forms/components/form-template-editor/FormTemplatePage.vue";
import FormTemplateConfirmationPage from "./FormTemplateConfirmationPage.vue";
import { interpolate } from "@/dictionary";
import Notify from "@/utils/notify";
import { IStyleDTOWithSassVariables } from "@/lib/formsServiceClient";
import { formTypeIcons } from "@/views/settings/forms/FormExtensions";
import { ComponentPublicInstance, computed, provide, reactive, ref } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import FormFieldTextInputInline from "@/components/common/text-input/FormFieldTextInputInline.vue";

import settings from "@/store/context/settings.context";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";

const store = useStore<RootState>();
const culture = store.getters["cultureStore/active"] as Culture;

const props = defineProps<{
  modelValue: FormDTO;
  newForm?: boolean;
}>();

const emit = defineEmits<{
  submit: [value: FormTemplateFormValues];
  "update:modelValue": [value: FormDTO];
}>();

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: () => emit("update:modelValue", props.modelValue),
});

// Start with one page on a new form
if (props.newForm) {
  modelValueInternal.value.addPage(1);
}

const currentLocales = computed(() =>
  modelValueInternal.value.getCurrentLocales(),
);

export interface FormTemplateFormValues {
  localizations: Record<Culture, { label: string }>;
}

const formTexts = texts.navigationItems.manage.settings.formTemplates;
const form = useForm<FormTemplateFormValues>({
  validationSchema: yup.object({
    localizations: yup
      .object(
        settings.availableLanguages.reduce(
          (acc, language) => ({
            ...acc,
            [language.locale.value as Culture]: yup.lazy((value) =>
              value
                ? yup.object({ label: yup.string().required() })
                : yup.mixed(),
            ),
          }),
          {},
        ),
      )
      .test(
        "localizations-complete",
        formTexts.localization.missingLocalizations, // Custom error message
        function (value) {
          const localizations = value as Record<Culture, { label: string }>;

          if (
            currentLocales.value.every(
              (locale) => localizations[locale].label === undefined,
            )
          ) {
            return true;
          }
          // Iterate over the current locales
          return currentLocales.value.every((locale) => {
            // Check if the localization for the current locale is defined and has a label
            return (
              localizations?.[locale as Culture] !== undefined &&
              localizations[locale as Culture]?.label !== undefined &&
              localizations[locale as Culture].label.trim() !== ""
            );
          });
        },
      ),
  }),
  initialValues: {
    localizations: modelValueInternal.value.localizations.reduce(
      (acc, language) => ({
        ...acc,
        [language.locale]: {
          label: language.label,
        },
      }),
      {},
    ),
  },
  validateOnMount: false,
});

const mainLanguageLocale = settings.mainLanguage.locale.value as Culture;

if (!currentLocales.value.some((locale) => locale === mainLanguageLocale)) {
  modelValueInternal.value.addLocalization(mainLanguageLocale);
}

const currentLocale = ref(mainLanguageLocale);

const formQuestions = computed(() => {
  return props.modelValue.pages.flatMap((page) => page.questions);
});
const formEditContext: FormTemplateEditContext = reactive({
  formType: FormType[props.modelValue.type as keyof typeof FormType],
  questions: [],
  formQuestions: formQuestions,
});

const formStyling = ref<IStyleDTOWithSassVariables | undefined>(undefined);

provide(FormTemplateEditContextKey, formEditContext);

const addLocalization = (locale: Culture) => {
  modelValueInternal.value.addLocalization(locale);
};

const removeLocalization = (locale: Culture) => {
  if (locale === mainLanguageLocale) {
    Notify.failure(formTexts.localization.deleteFailedNotification);
    return;
  }
  modelValueInternal.value.removeLocalization(locale);
};

const initialize = async () => {
  formEditContext.questions = await formsServiceClient.getAllQuestions();
  const styling = useFormStyling(props.modelValue.formUri);
  const fallBackStyling = useFormStyling();

  await styling.load();
  await fallBackStyling.load();

  formStyling.value = styling.data.value ?? fallBackStyling.data.value;
};
initialize();

const badgeLabel = computed<string | undefined>(() => {
  const type = modelValueInternal.value.type;
  if (type) {
    const formType = type as keyof typeof texts.enums.formType;
    return texts.enums.formType[formType];
  }

  return undefined;
});

const labelFocus = ref(false);

// Scroll Functions
let pageRefs: Record<number, Element> = {};
const confirmPageRef = ref<Element | null>(null);

const createHandler = (index: number) => {
  return (ele: Element | ComponentPublicInstance | null) => {
    setPageRef(index, ele);
  };
};

const setPageRef = (
  index: number,
  ele: Element | ComponentPublicInstance | null,
) => {
  if (ele instanceof Element) {
    pageRefs[index] = ele;
  }
};

const scrollPageUp = (index: number) => {
  const prevPage = pageRefs[index - 1];
  prevPage.scrollIntoView({ behavior: "smooth" });
};

const scrollPageDown = (index: number) => {
  const nextPage = pageRefs[index + 1];
  nextPage.scrollIntoView({ behavior: "smooth" });
};

const scrollPageConfirm = () => {
  confirmPageRef.value?.scrollIntoView({ behavior: "smooth" });
};

const onSubmit = form.handleSubmit((values) => {
  emit("submit", values);
});
</script>
