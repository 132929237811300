<template>
  <component :is="'article'">
    <Card
      v-if="departments.length > 0 && max > 0"
      :size="CardSize.lg"
      class="flex flex-col gap-4"
    >
      <Loader v-if="loading" />
      <template v-else>
        <CardHeader
          :text="
            interpolate(
              texts.navigationItems.organize.activity.reports
                .departementStudyProgramGraph.title,
              texts.models.studyProgramDepartment.title,
            )
          "
        ></CardHeader>
        <RegistrantAndVisitorLegend />
        <div :class="['flex flex-col gap-4', loading ? 'opacity-40' : '']">
          <Collapsible
            v-for="(department, index) of departments"
            :key="department.id"
            v-model:expanded="department.expanded"
          >
            <button
              type="button"
              class="flex gap-2 self-center print:hidden"
              @click="clickHandler(department)"
            >
              <Chevron
                v-if="!department.loading"
                :expanded="department.expanded"
              />
              <Spinner
                v-if="department.loading"
                :color="Color.Lavender"
                :size="IconSize.xs"
              />
            </button>
            <StatsBarComparableListItem
              :key="`comparable-item-${index}`"
              :items="department.items"
              :max="max"
              class="flex-1"
            >
              <span :title="department.name">{{ department.name }}</span>
            </StatsBarComparableListItem>
            <template
              v-if="
                department.studyPrograms && department.studyPrograms?.length > 0
              "
              v-slot:expanded
            >
              <StatsBarComparableListItem
                v-for="(studyProgram, i) in department.studyPrograms"
                :key="`comparable-sub-item-${i}`"
                :items="studyProgram.items"
                :intensity="4"
                :max="max"
                class="pl-6"
              >
                <span :title="studyProgram.name">{{ studyProgram.name }}</span>
              </StatsBarComparableListItem>
            </template>
          </Collapsible>
        </div>
      </template>
    </Card>
  </component>
</template>

<script setup lang="ts">
import { CardSize } from "@/components/common/card/Card.types";
import Card from "@/components/common/card/Card.vue";
import Spinner from "@/components/common/spinner/Spinner.vue";
import Chevron from "@/components/common/chevron/Chevron.vue";
import texts from "@/utils/texts";
import CardHeader from "@/components/common/card/CardHeader.vue";
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Loader from "@/components/common/loader/Loader.vue";
import StatsBarComparableListItem from "@/components/common/stats-bar/StatsBarComparableListItem.vue";
import { Color } from "@/enums";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { computed, ref } from "vue";
import { interpolate } from "@/dictionary";
import RegistrantAndVisitorLegend from "@/components/common/legend/RegistrantAndVisitorLegend.vue";

const props = defineProps<{ activityId: string }>();

type DepartmentStat = {
  id?: string;
  name: string;
  items: { count: number; legend: string }[];
  loading: boolean;
  expanded: boolean;
  studyPrograms?: {
    id?: string;
    name: string;
    items: { count: number; legend: string }[];
  }[];
};

const loading = ref(true);
const departments = ref<DepartmentStat[]>([]);

eduConfigurationServiceClient
  .getProspectNumbersForDepartments(props.activityId, undefined)
  .then((response) => {
    departments.value = response.departments.map((department) => ({
      id: department.id,
      name: department.id ? department.name : texts.generic.unknown,
      items: [
        {
          count: department.count,
          legend:
            texts.navigationItems.organize.activity.reports.registrations.title,
        },
        {
          count: department.countWithVisit ?? 0,
          legend:
            texts.navigationItems.organize.activity.reports.visitors.title,
        },
      ],
      loading: false,
      expanded: false,
      studyPrograms: undefined,
    }));
  })
  .catch((e) => logger.error(e))
  .finally(() => (loading.value = false));

const clickHandler = (department: DepartmentStat) => {
  department.expanded = !department.expanded;
  if (department.studyPrograms) return;
  department.loading = true;

  const promise = !department.id
    ? eduConfigurationServiceClient.getProspectNumbersForUnknownDepartmentInterests(
        props.activityId,
        undefined,
      )
    : eduConfigurationServiceClient.getProspectNumbersForDepartmentInterests(
        department?.id,
        props.activityId,
        undefined,
      );

  promise
    .then((response) => {
      department.studyPrograms = response.studyPrograms.map((studyProgram) => ({
        id: studyProgram.id,
        name: studyProgram.displayName,
        items: [
          {
            count: studyProgram.count,
            legend:
              texts.navigationItems.organize.activity.reports.registrations
                .title,
          },
          {
            count: studyProgram.countWithVisit ?? 0,
            legend:
              texts.navigationItems.organize.activity.reports.visitors.title,
          },
        ],
      }));
    })
    .catch((e) => logger.error(e))
    .finally(() => (department.loading = false));
};

const max = computed(() =>
  departments.value.reduce(
    (acc, curr) => Math.max(acc, ...curr.items.map((i) => i.count)),
    0,
  ),
);
</script>
