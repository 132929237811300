<template>
  <DescriptionList>
    <DescriptionListItem :name="texts.models.mailing.type">
      <SingleValue
        :value="data.type ? texts.enums.mailingType[data.type] : ''"
      />
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.locale">
      <SingleValue :value="data.localeName" />
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.name">
      <SingleValue :value="data.name || ''" />
    </DescriptionListItem>
    <DescriptionListItem
      v-if="data.type !== MailingType.General"
      :name="texts.models.mailing.activity"
    >
      <SingleValue :value="data.activityName || ''" />
    </DescriptionListItem>
    <DescriptionListItem
      v-if="data.type === MailingType.ActivityInvite"
      :name="texts.models.mailing.registrationLink"
    >
      <SingleValue :value="data.registrationLinkName || ''" />
    </DescriptionListItem>
    <DescriptionListItem
      v-if="data.type === MailingType.ActivityVisitedSurvey"
      :name="texts.models.mailing.surveyForm"
    >
      <SingleValue :value="data.formName || ''" />
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.subject">
      <SingleValue :value="data.subject || ''" />
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.fromName">
      <SingleValue :value="data.fromName || ''" />
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.fromEmailAddress">
      <SingleValue :value="data.fromEmailAddress || ''" />
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.replyToEmailAddress">
      <SingleValue :value="data.replyToEmailAddress || ''" />
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.selectionDefinition">
      <Async :state="state">
        <template v-slot:failure>
          <ErrorComponent />
        </template>
        <template v-slot:loading>
          <Loader />
        </template>
        <template v-slot>
          <div class="col-span-2 flex flex-col gap-4">
            <SelectionDefinition
              v-if="selectionContext && data.selectionDefinition"
              :modelValue="data.selectionDefinition"
              :context="selectionContext"
              readonly
            >
              <SelectionCountForMailing
                :definition="data.selectionDefinition"
                @results="(value) => (selectionResults = value)"
              />
            </SelectionDefinition>
            <MailingRecipientsAlert
              v-if="selectionResults"
              :selectionResultCounts="selectionResults"
            ></MailingRecipientsAlert>
          </div>
        </template>
      </Async>
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.template">
      <MailingPreviewButton
        class="justify-self-start"
        :label="texts.actions.showPreview"
        :mailingContent="data.mailingContent"
      />
    </DescriptionListItem>
    <DescriptionListItem :name="texts.models.mailing.timing">
      <SingleValue
        :value="
          data.datetime
            ? data.datetime.toLocaleString(DateTime.DATETIME_MED)
            : ''
        "
      />
    </DescriptionListItem>
  </DescriptionList>
</template>

<script setup lang="ts">
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Culture } from "@/enums";
import dictionary from "@/dictionary";

// Components
import DescriptionList from "@/components/common/description-list/DescriptionList.vue";
import DescriptionListItem from "@/components/common/description-list/DescriptionListItem.vue";
import SingleValue from "@/components/common/description-list/DescriptionListSingleValue.vue";
import { MailingDescriptionListData } from "./MailingDescriptionList.types";
import MailingPreviewButton from "@/views/mailings/mailing/components/MailingPreviewButton.vue";
import MailingRecipientsAlert from "@/views/mailings/mailing/components/MailingRecipientsAlert.vue";
import { MailingType } from "@/enums";
import SelectionDefinition from "@/components/selection/SelectionDefinition.vue";
import { loadCriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import { useAsyncState } from "@/components/common/async/Async.types";
import Async from "@/components/common/async/Async.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import { ref } from "vue";
import { SelectionResultsCountDto } from "@/lib/eduConfigurationServiceClient";
import { DateTime } from "luxon";
import SelectionCountForMailing from "@/views/mailings/mailing/steps/components/SelectionCountForMailing.vue";

const props = defineProps<{
  data: MailingDescriptionListData;
}>();

const {
  state,
  handler,
  response: selectionContext,
} = useAsyncState(() => loadCriterionFieldContext(props.data.activityId));
handler();

// Store setup
const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const selectionResults = ref<SelectionResultsCountDto>();
</script>
