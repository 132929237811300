<template>
  <FormLayout @submit="submitForm">
    <h2
      v-if="!initialValues?.activityTypeId"
      class="text-lg font-semibold text-deepteal-700"
    >
      {{ texts.navigationItems.organize.styling.title }}
    </h2>
    <div
      v-if="!initialValues?.activityTypeId"
      class="flex items-center justify-between"
    >
      <h3 class="text-base font-semibold text-deepteal-700">
        {{ texts.navigationItems.organize.styling.publicActivityPage.title }}
      </h3>
    </div>

    <FormFieldTextInput
      id="color"
      :label="texts.models.publicActivityPageSettings.color"
      :type="TextInputType.COLOR"
    />
    <FormFieldTextInput
      id="bannerUrl"
      :label="texts.models.publicActivityPageSettings.bannerUrl"
      :type="TextInputType.URL"
    />
    <FormFieldTextInput
      id="gtmCode"
      :label="texts.models.publicActivityPageSettings.gtmCode"
    />
    <Card v-if="form.values.bannerUrl" class="flex flex-col gap-4">
      <CardHeader :text="texts.generic.preview"></CardHeader>
      <img :src="form.values.bannerUrl" />
    </Card>

    <NavigationTabContainer>
      <NavigationTab
        v-for="lang in settings.availableLanguages"
        :key="lang.locale.value"
        :current="activeCulture === lang.locale.value"
        @click="activeCulture = lang.locale.value as Culture"
        >{{ texts.enums.culture[lang.locale.value as Culture] }}</NavigationTab
      >
    </NavigationTabContainer>
    <FormFieldArrayHelper
      v-slot="{ key, field }"
      :fieldId="fieldId"
      :fields="localizations"
    >
      <FormFieldTextInput
        v-show="activeCulture === field.locale"
        :id="`${key}.title`"
        :label="`${texts.models.publicActivityPageSettings.pagetitle} (${
          texts.enums.culture[field.locale]
        })`"
      />
      <FormFieldWysiwyg
        v-show="activeCulture === field.locale"
        :id="`${key}.description`"
        :label="`${texts.models.publicActivityPageSettings.pagedescription} (${
          texts.enums.culture[field.locale]
        })`"
      />
    </FormFieldArrayHelper>
    <template v-slot:actions> <slot></slot> </template>
  </FormLayout>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import NavigationTab from "@/components/common/navigation/NavigationTab.vue";
import NavigationTabContainer from "@/components/common/navigation/NavigationTabContainer.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";
import { Culture } from "@/enums";
import { IPublicActivityPageSettingsDTO } from "@/lib/eduConfigurationServiceClient";
import {
  normalizeLocalizations,
  useLocalizationFields,
} from "@/models/helpers/localizable";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import { ref, watch } from "vue";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import FormLayout from "@/components/common/form/FormLayout.vue";
import settings from "@/store/context/settings.context";

export interface PublicActivityPageSettingsFormValues {
  activityTypeId?: string;
  color: string;
  bannerUrl?: string;
  gtmCode?: string;
  localizations: PublicActivityPageSettingsFormValuesLocalization[];
  activityTypeLocalizations: PublicActivityPageSettingsFormValuesLocalization[];
}

interface PublicActivityPageSettingsFormValuesLocalization {
  locale: Culture;
  title?: string;
  description?: string;
}

const props = defineProps<{
  initialValues: IPublicActivityPageSettingsDTO;
}>();

const emit = defineEmits<{
  submit: [values: PublicActivityPageSettingsFormValues];
}>();

const localizationSchema = yup
  .array()
  .of(
    yup.object().shape({
      locale: yup.mixed<Culture>().required(),
      title: yup.string().max(256),
      description: yup.string().max(1024),
    }),
  )
  .required();

const form = useForm<PublicActivityPageSettingsFormValues>({
  validationSchema: yup.object({
    activityTypeId: yup.string(),
    color: yup.string().required(),
    bannerUrl: yup.string(),
    gtmCode: yup.string(),
    localizations: localizationSchema,
    activityTypeLocalizations: localizationSchema,
  }),
});

const localizationFieldId = props.initialValues?.activityTypeId
  ? fields<PublicActivityPageSettingsFormValues>().activityTypeLocalizations
  : fields<PublicActivityPageSettingsFormValues>().localizations;

const { fieldId, fields: localizations } =
  useLocalizationFields(localizationFieldId);

const submitForm = form.handleSubmit((values) => emit("submit", values));

const initializeFormValues = (newSettings: IPublicActivityPageSettingsDTO) => {
  if (newSettings) {
    const localizations = normalizeLocalizations(
      newSettings.localizations as PublicActivityPageSettingsFormValuesLocalization[],
    );

    const values: PublicActivityPageSettingsFormValues = {
      activityTypeId: newSettings.activityTypeId,
      color: newSettings.color,
      bannerUrl: newSettings.bannerUrl,
      gtmCode: newSettings.gtmCode,
      localizations: [],
      activityTypeLocalizations: [],
    };

    values[localizationFieldId] = localizations;

    form.setValues(values);
  }
};

watch(
  props.initialValues,
  (newSettings) => {
    initializeFormValues(newSettings);
  },
  { immediate: true },
);

const activeCulture = ref(Culture.NL);
</script>
