import { RouteNames } from "@/router/routeNames";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import { userCanGoTo } from "../helpers/userCanGoTo";

const authorizationGuard: NavigationGuard = (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (userCanGoTo(to)) next();

  next({ name: RouteNames.UNAUTHORIZED });
};

export default authorizationGuard;
