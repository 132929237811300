<template>
  <template v-if="edit">
    <div>
      <Select
        :id="fieldId"
        v-model="typeOption"
        :name="fieldId"
        :options="items"
      />
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <span
      :class="[
        'border border-transparent text-sm text-deepteal-600 xl:text-lg xl:font-normal',
        !criterion.isReadOnly ? '' : '',
      ]"
    >
      {{ texts.enums.selectionType[criterion.type] }}
    </span>
  </template>
</template>

<script setup lang="ts">
import Select from "@/components/common/select/Select.vue";
import texts from "@/utils/texts";
import { computed } from "vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import { useField } from "vee-validate";
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import * as yup from "yup";
import {
  SelectionCriterionDto,
  SelectionCriterionDtoType,
} from "@/lib/eduConfigurationServiceClient";
import { fields } from "@/utils/miscellaneous";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().type;

const { value, errorMessage } = useField(
  fieldId,
  yup.mixed<SelectionCriterionDtoType>().required(),
  { initialValue: props.criterion.type },
);

const items = computed<SelectOption[]>(() =>
  Object.values(SelectionCriterionDtoType)
    .filter((type) => type !== SelectionCriterionDtoType.Unknown)
    .map((type) => ({
      label: texts.enums.selectionType[type],
      value: type,
    }))
    .filter(
      (type) => !props.exclude || props.exclude?.includes(type.value) === false,
    ),
);

const typeOption = computed({
  get: () => items.value.find((item) => item.value === value.value),
  set: (option: SelectOption | undefined) => {
    value.value = option?.value as SelectionCriterionDtoType;
  },
});
</script>
