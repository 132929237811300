<template>
  <button :disabled="disabledInternal" :type="typeInternal" :class="classes">
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";
import { ButtonElementType, ButtonSize } from "./Button.types";
import { ButtonGroupInjectionKey } from "./ButtonGroup.types";
import { Color } from "@/enums";
import {
  getBgColor,
  getForegroundColor,
  getBgHoverColor,
  getTextColor,
  getFocusRingColor,
} from "@/utils/color";

const props = withDefaults(
  defineProps<{
    color?: Color;
    size?: ButtonSize;
    type?: ButtonElementType;
    grouped?: boolean;
    flat?: boolean;
    round?: boolean;
    disabled?: boolean;
  }>(),
  {
    color: Color.Emerald,
    size: ButtonSize.md,
    type: ButtonElementType.button,
    grouped: false,
    flat: false,
    round: false,
    disabled: false,
  },
);

const isGrouped = computed<boolean>(() =>
  inject(ButtonGroupInjectionKey.Grouped, props.grouped),
);

const colorInternal = computed<Color>(() =>
  inject(ButtonGroupInjectionKey.Color, props.color),
);

const sizeInternal = computed<ButtonSize>(() =>
  inject(ButtonGroupInjectionKey.Size, props.size),
);

const typeInternal = computed<ButtonElementType>(() =>
  inject(ButtonGroupInjectionKey.Type, props.type),
);

const flatInternal = computed<boolean>(() =>
  inject(ButtonGroupInjectionKey.Flat, props.flat),
);

const roundInternal = computed<boolean>(() =>
  inject(ButtonGroupInjectionKey.Round, props.round),
);

const disabledInternal = computed<boolean>(() =>
  inject(ButtonGroupInjectionKey.Disabled, props.disabled),
);

const gap = computed<boolean>(() => inject(ButtonGroupInjectionKey.Gap, false));

const classes = computed(() => [
  "relative inline-flex items-center justify-center gap-2 print:hidden text-nowrap",
  gap.value ? "pl-1 pr-1 first:pl-2 last:pr-2 py-2" : "",
  !gap.value && roundInternal.value
    ? {
        "p-1": sizeInternal.value === ButtonSize.xs,
        "p-2":
          sizeInternal.value === ButtonSize.sm ||
          sizeInternal.value === ButtonSize.md,
        "p-3":
          sizeInternal.value === ButtonSize.lg ||
          sizeInternal.value === ButtonSize.xl,
      }
    : {
        "px-4 py-2":
          sizeInternal.value === ButtonSize.xs ||
          sizeInternal.value === ButtonSize.sm ||
          sizeInternal.value === ButtonSize.md,
        "px-6 py-3":
          sizeInternal.value === ButtonSize.lg ||
          sizeInternal.value === ButtonSize.xl,
      },
  {
    "text-xs font-medium": sizeInternal.value === ButtonSize.xs,
    "text-sm font-semibold": sizeInternal.value === ButtonSize.sm,
    "text-base font-medium": sizeInternal.value === ButtonSize.md,
    "text-lg font-normal":
      sizeInternal.value === ButtonSize.lg ||
      sizeInternal.value === ButtonSize.xl,
  },
  flatInternal.value ? "" : "shadow-sm",
  isGrouped.value
    ? {
        "first:rounded-l-md last:rounded-r-md": !roundInternal.value,
        "first:rounded-l-full last:rounded-r-full": roundInternal.value,
      }
    : {
        rounded: !roundInternal.value && sizeInternal.value === ButtonSize.xs,
        "rounded-md":
          !roundInternal.value && sizeInternal.value !== ButtonSize.xs,
        "rounded-full": roundInternal.value,
      },
  isGrouped.value ? "border-b border-t first:border-l last:border-r" : "border",
  {
    "border-transparent": colorInternal.value !== Color.White,
    "border-gray-300": colorInternal.value === Color.White,
  },
  getBgColor(colorInternal.value),
  getTextColor(getForegroundColor(colorInternal.value)),
  !disabledInternal.value && [
    "focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2",
    getBgHoverColor(colorInternal.value),
    getFocusRingColor(colorInternal.value),
  ],
  {
    "opacity-50 cursor-not-allowed": disabledInternal.value,
  },
]);
</script>
