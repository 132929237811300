<template>
  <Page :loading="loading" :error="error">
    <Section>
      <PageHeading :title="textsView.title" icon="bookmark">
        <template v-slot:description>{{
          texts.navigationItems.studyPrograms.areasOfInterest.description
        }}</template>
        <template v-slot:actions>
          <ButtonAdd @click="loadCreateForm"></ButtonAdd>
        </template>
      </PageHeading>
    </Section>
    <Section>
      <List>
        <template v-slot:header>
          <ListItemColumn>{{ textsView.name }}</ListItemColumn>
          <ListItemColumn></ListItemColumn>
        </template>
        <ListItem
          v-for="item in overview"
          :key="item.id"
          clickable
          @click="loadUpdateForm(item)"
        >
          <ListItemColumn>{{ item.name }}</ListItemColumn>
          <ListItemColumn :flex="0">
            <ListItemActions @click.stop>
              <DropdownItem @click="loadUpdateForm(item)">
                <Icon icon="edit" :color="Color.DeepTeal"></Icon>
                <span class="text-deepteal-500">{{ texts.actions.edit }}</span>
              </DropdownItem>
              <Divider class="mx-2 my-1" />
              <DropdownItem @click="openDeleteModal(item.id)">
                <Icon icon="delete" :color="Color.Alert"></Icon>
                <span class="text-alert-500">{{ texts.actions.delete }}</span>
              </DropdownItem>
            </ListItemActions>
          </ListItemColumn>
        </ListItem>
      </List>
    </Section>
  </Page>

  <!-- Create slideover -->
  <SlideOver v-model:visible="createSlideOverVisible" :title="textsView.title">
    <MetadataForm
      :loading="createActionLoading"
      @submit="submitCreateForm"
      @cancel="createSlideOverVisible = false"
    >
      <ButtonSubmit :loading="loading" />
      <ButtonCancel @click="createSlideOverVisible = false" />
    </MetadataForm>
  </SlideOver>

  <!-- Edit slideover -->
  <SlideOver v-model:visible="updateSlideOverVisible" :title="textsView.title">
    <Loader v-if="updateFormLoading" />
    <MetadataForm
      v-else
      :formValues="formValues"
      :loading="updateActionLoading"
      @submit="submitUpdateForm"
    >
      <template v-if="!deleting">
        <ButtonSubmit :loading="loading" />
        <ButtonCancel @click="updateSlideOverVisible = false" />
        <ButtonDelete @click="deleting = true"></ButtonDelete>
      </template>
      <template v-else>
        <ConfirmDeleteDialogButtons
          :loading="loading"
          @confirm="submitDelete"
          @cancel="deleting = false"
        ></ConfirmDeleteDialogButtons>
      </template>
    </MetadataForm>
  </SlideOver>
  <DeleteAreaOfInterestModal
    v-if="areaOfInterestIdToDelete"
    :deletingId="areaOfInterestIdToDelete"
    @update:deletingId="deleteAreaOfInterest"
  ></DeleteAreaOfInterestModal>
</template>

<script setup lang="ts">
import { ref, shallowRef, watch } from "vue";
import logger from "@/plugins/logger";

// Components
import Page from "@/components/common/page/Page.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Section from "@/components/common/section/Section.vue";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Divider from "@/components/common/divider/Divider.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import {
  CreateStudyProgramAreaOfInterestDto,
  LocalizedStudyProgramAreaOfInterestDto,
  StudyProgramAreaOfInterestDto,
  UpdateStudyProgramAreaOfInterestDto,
} from "@/lib/eduConfigurationServiceClient";

import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ConfirmDeleteDialogButtons from "@/components/common/button/ConfirmDeleteDialogButtons.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import Loader from "@/components/common/loader/Loader.vue";
import MetadataForm from "@/views/settings/study-programs/components/MetadataForm.vue";
import {
  convertToFormValues,
  MetadataFormValues,
} from "@/views/settings/study-programs/components/MetadataForm.types";
import DeleteAreaOfInterestModal from "./DeleteAreaOfInterestModal.vue";

const textsView = texts.models.studyProgramAreaOfInterest;

// Overview
const overview = shallowRef<LocalizedStudyProgramAreaOfInterestDto[]>([]);
const loading = ref<boolean>(false);
const error = ref<boolean>(false);
const refreshOverview = async () => {
  loading.value = true;
  try {
    overview.value =
      await eduConfigurationServiceClient.getStudyProgramAreasOfInterest();
  } catch (e) {
    error.value = true;
    logger.error(e);
  } finally {
    loading.value = false;
  }
};
refreshOverview();

// Create SlideOver
const createSlideOverVisible = ref(false);
const createActionLoading = ref(false);

const loadCreateForm = () => {
  createSlideOverVisible.value = true;
};

const submitCreateForm = async (values: MetadataFormValues) => {
  createActionLoading.value = true;
  try {
    await eduConfigurationServiceClient.createStudyProgramAreaOfInterest(
      new CreateStudyProgramAreaOfInterestDto({
        sequenceNumber: values.sequenceNumber,
        localizations: values.localizations.filter((loc) => loc.name),
        isArchived: false,
      }),
    );
    Notify.success(texts.notifications.create.successCached, [textsView.title]);
    createSlideOverVisible.value = false;
    refreshOverview();
  } catch (e) {
    Notify.failure(texts.notifications.create.failure, [textsView.title]);
    logger.error(e);
  } finally {
    createActionLoading.value = false;
  }
};

// Edit/Update SlideOver
const current = ref<StudyProgramAreaOfInterestDto>();
const formValues = ref<MetadataFormValues>();

const updateSlideOverVisible = ref(false);
const updateActionLoading = ref(false);
const updateFormLoading = ref(false);

const loadUpdateForm = async (item: LocalizedStudyProgramAreaOfInterestDto) => {
  updateSlideOverVisible.value = true;
  updateFormLoading.value = true;
  try {
    await eduConfigurationServiceClient
      .getStudyProgramAreaOfInterestById(item.id)
      .then((dto) => {
        current.value = dto;
        formValues.value = convertToFormValues(dto);
      });
  } catch (e) {
    error.value = true;
    logger.error(e);
  } finally {
    updateFormLoading.value = false;
  }
};

const submitUpdateForm = async (values: MetadataFormValues) => {
  if (!current.value) throw new Error("Cannot update item, id is missing");
  updateActionLoading.value = true;
  try {
    await eduConfigurationServiceClient.updateStudyProgramAreaOfInterest(
      current.value.id,
      new UpdateStudyProgramAreaOfInterestDto({
        sequenceNumber: values.sequenceNumber,
        localizations: values.localizations.filter((loc) => loc.name),
        isArchived: false,
      }),
    );
    Notify.success(texts.notifications.edit.successCached, [textsView.title]);
    updateSlideOverVisible.value = false;
    refreshOverview();
  } catch (e) {
    Notify.failure(texts.notifications.edit.failure, [textsView.title]);
    logger.error(e);
  } finally {
    updateActionLoading.value = false;
  }
};

const areaOfInterestIdToDelete = ref<string | undefined>(undefined);
// Delete AreaOfInterest
const openDeleteModal = (areaOfInterestId: string) => {
  areaOfInterestIdToDelete.value = areaOfInterestId;
};

const deleting = ref(false);
const deletingItemLoading = ref(false);

const submitDelete = async () => {
  if (!current.value) throw new Error("Cannot delete item, id is missing");

  deletingItemLoading.value = true;
  await eduConfigurationServiceClient
    .deleteStudyProgramAreaOfInterest(current.value.id)
    .then(() => {
      updateSlideOverVisible.value = false;
      Notify.success(texts.notifications.delete.success, [textsView.title]);
      refreshOverview();
    })
    .catch((e) => {
      Notify.failure(texts.notifications.delete.failure, [textsView.title]);
      throw e;
    })
    .finally(() => {
      deletingItemLoading.value = false;
    });
};

watch(updateSlideOverVisible, (isVisible) => {
  if (!isVisible) deleting.value = false;
});

const deleteAreaOfInterest = () => {
  areaOfInterestIdToDelete.value = undefined;
  refreshOverview();
};
</script>
