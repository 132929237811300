<template>
  <div class="flex h-full max-w-2xl flex-col gap-8">
    <div class="flex flex-col gap-2">
      <PageHeading>{{
        texts.navigationItems.organize.activity.notificationSettings.title
      }}</PageHeading>
      <PageSubTitle>
        {{
          texts.navigationItems.organize.activity.notificationSettings
            .description
        }}</PageSubTitle
      >
    </div>

    <Loader v-if="loading" />
    <NotificationSettingsForm
      v-else
      :formValues="settings"
      :activityId="props.id"
      @submit="onSubmit"
    >
      <template v-slot:actions>
        <div class="flex items-center justify-between gap-4">
          <ButtonSubmit :loading="actionLoading" />
          <ButtonDelete
            :loading="actionLoading"
            :disabled="!settings"
            @click="onDelete"
          />
        </div>
      </template>
    </NotificationSettingsForm>
  </div>
</template>

<script setup lang="ts">
import NotificationSettingsForm from "@/views/organize/activities/activity/notification-settings/NotificationSettingsForm.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import { ref } from "vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Loader from "@/components/common/loader/Loader.vue";
import {
  ActivityNotificationSettingsDTO,
  ApiException,
  IActivityNotificationSettingsDTO,
} from "@/lib/eduConfigurationServiceClient";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";

const props = defineProps<{
  // is actually an activityId
  id: string;
}>();

const loading = ref(true);
const actionLoading = ref(false);
const settings = ref<IActivityNotificationSettingsDTO>();

const fetchSettings = () => {
  eduConfigurationServiceClient
    .getActivityNotificationSettings(props.id)
    .then((response) => {
      settings.value = response;
    })
    .catch((error) => {
      // TODO: refactor this to some kind of error handling service, util or something
      if (error instanceof ApiException && error.status == 404) {
        // This won't change the expression settings.value,
        //   it's here to remind future maintainers that this is intended behavior: 404 -> no settings yet
        settings.value = undefined;
      } else {
        throw error;
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const onDelete = () => {
  actionLoading.value = true;
  eduConfigurationServiceClient
    .deleteActivityNotificationSettings(props.id)
    .then(() => {
      Notify.success(texts.navigationItems.organize.activity.edit.success);
      settings.value = undefined;
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.organize.activity.edit.failure);
      throw error;
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const onSubmit = (values: IActivityNotificationSettingsDTO) => {
  actionLoading.value = true;
  eduConfigurationServiceClient
    .updateActivityNotificationSettings(
      props.id,
      new ActivityNotificationSettingsDTO(values),
    )
    .then(() => {
      Notify.success(texts.navigationItems.organize.activity.edit.success);
      settings.value = values;
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.organize.activity.edit.failure);
      throw error;
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

fetchSettings();
</script>
