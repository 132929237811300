<template>
  <form class="flex flex-col gap-8" @submit="submitForm">
    <LocalizationTabs
      v-model="activeCulture"
      :localizations="cultures"
      @add="addLocale"
      @remove="removeLocale"
    />
    <FormFieldTextInput
      v-for="culture in cultures"
      v-show="activeCulture === culture"
      :id="`localizations.${culture}.name`"
      :key="culture"
      :label="texts.models.registrationLink.name"
      :data-testid="`name_${culture}`"
      :displayMode="FormFieldDisplayMode.Row"
    />

    <FormFieldToggle
      id="isVisibleForProspects"
      :label="
        texts.navigationItems.organize.activity.form.isVisibleForProspects
      "
      :displayMode="FormFieldDisplayMode.Row"
    />

    <FormFieldSelect
      id="formId"
      :label="texts.models.registrationLink.form"
      :items="
        settings.forms.map((f) => ({
          label: f.name,
          value: f.id,
        }))
      "
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldSelect
      id="confirmationEmailTemplateId"
      :label="texts.models.registrationLink.confirmationEmailTemplate"
      :items="
        settings.emailTemplates
          .filter((i) => i.type == EmailTemplateType.RegistrationConfirmation)
          .map((et) => ({
            label: et.name,
            value: et.id,
          }))
      "
      :displayMode="FormFieldDisplayMode.Row"
    />

    <SectionDivider />

    <div class="flex items-center justify-between gap-4">
      <ButtonSubmit
        :loading="createLoading"
        :trailingIcon="redirectToHub ? 'arrow_right_alt' : undefined"
      >
        <span
          v-if="redirectToHub"
          class="inline-flex flex-wrap items-center gap-1"
        >
          <span class="font-semibold">{{ texts.actions.create }},</span>
          <span class="font-normal">{{ textsRegLink.btnToHub }}</span>
        </span>
        <span v-else>{{ texts.actions.create }}</span>
      </ButtonSubmit>
      <ButtonCancel v-if="!redirectToHub" @click="emit('create:cancel')" />
    </div>
  </form>
</template>

<script setup lang="ts">
import {
  RegistrationLinkCreateFormValues,
  convertToCreateDTO,
} from "./RegistrationLinkForm.types";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { Culture } from "@/enums";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import SectionDivider from "@/components/common/section/SectionDivider.vue";
import texts from "@/utils/texts";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import settings from "@/store/context/settings.context";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import { computed, ref } from "vue";
import { EmailTemplateType } from "@/enums";
import { ActivityDTO } from "@/lib/eduConfigurationServiceClient";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import store from "@/store/index";

const defaultCulture = store.getters["cultureStore/mainCulture"] as Culture;

const props = defineProps<{
  activity: ActivityDTO;
  redirectToHub: boolean;
}>();

const emit = defineEmits<{
  "create:cancel": [];
  "create:success": [];
}>();

const cultures = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

const textsRegLink = texts.navigationItems.organize.activity.registrationLinks;

const form = useForm<RegistrationLinkCreateFormValues>({
  validationSchema: yup.object({
    localizations: yup.object(
      settings.availableLanguages.reduce(
        (acc, language) => ({
          ...acc,
          [language.locale.value as Culture]: yup.lazy((value) =>
            value
              ? yup.object({
                  locale: yup.mixed<Culture>().required(),
                  name: yup.string().max(256).required(),
                  prospectAppDescription: yup.string().max(1024),
                })
              : yup.mixed(),
          ),
        }),
        {},
      ),
    ),
    formId: yup.string().required(),
    confirmationEmailTemplateId: yup.string(),
  }),
});

const activeCulture = ref(Culture.NL);

function addLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    addToLocalizations(form.values.localizations, culture, {
      locale: culture,
      name: "",
    }),
  );
}

function removeLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    removeFromLocalizations(form.values.localizations, culture),
  );
}

function addToLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
  value: T,
) {
  return { ...localizations, [culture]: value };
}

function removeFromLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
) {
  const newDict = { ...localizations };
  delete newDict[culture];
  return newDict;
}

const createLoading = ref<boolean>(false);
const submitForm = form.handleSubmit((values) => {
  createLoading.value = true;

  const dto = convertToCreateDTO(
    values as RegistrationLinkCreateFormValues,
    settings.forms,
  );

  eduConfigurationServiceClient
    .postRegistrationLink(props.activity.id, dto)
    .then(() => {
      Notify.success(textsRegLink.create.success);
      emit("create:success");
    })
    .catch((e) => {
      Notify.failure(textsRegLink.create.failure);
      logger.error(e);
    })
    .finally(() => {
      createLoading.value = false;
    });
});
</script>
