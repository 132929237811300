<template>
  <SwitchGroup
    as="div"
    class="relative flex min-w-fit rounded-full bg-gray-100 hover:bg-gray-200"
  >
    <Switch
      v-model="enabledInternal"
      :class="[
        { 'cursor-not-allowed opacity-50': isDisabled },
        'bg-transparent',
        'relative z-20 grid w-full min-w-fit cursor-pointer grid-cols-2 items-center justify-evenly gap-8 rounded-full border border-transparent text-center transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2',
        {
          'text-xs font-medium': size === ButtonSize.xs,
          'text-sm font-semibold': size === ButtonSize.sm,
          'text-base font-medium': size === ButtonSize.md,
          'text-lg font-normal':
            size === ButtonSize.lg || size === ButtonSize.xl,
        },
        {
          'p-1': size === ButtonSize.xs,
          'p-2': size === ButtonSize.sm || size === ButtonSize.md,
          'p-3': size === ButtonSize.lg || size === ButtonSize.xl,
        },
      ]"
    >
      <div
        class="flex items-center justify-center gap-1 text-nowrap pl-3 text-center"
        :class="[enabledInternal ? 'text-emerald-500' : 'text-deepteal-500']"
      >
        <Icon
          :icon="icons[0]"
          :size="iconSize"
          :color="enabledInternal ? Color.Emerald : Color.DeepTeal"
        ></Icon>
        <span class="whitespace-nowrap text-nowrap">{{ labels[0] }}</span>
      </div>
      <div
        class="flex items-center justify-center gap-1 text-nowrap pr-3 text-center"
        :class="[enabledInternal ? 'text-deepteal-500' : 'text-emerald-500']"
      >
        <Icon
          :icon="icons[1]"
          :size="iconSize"
          :color="enabledInternal ? Color.DeepTeal : Color.Emerald"
        ></Icon>
        <span class="whitespace-nowrap text-nowrap">{{ labels[1] }}</span>
      </div>
    </Switch>
    <span
      aria-hidden="true"
      :class="[
        enabledInternal ? 'translate-x-0' : 'translate-x-full',
        'pointer-events-none absolute z-10 h-full w-1/2 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
      ]"
    />
  </SwitchGroup>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Switch, SwitchGroup } from "@headlessui/vue";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import { IconNames, IconSize } from "@/components/common/icon/Icon.types";
import { ButtonSize } from "@/components/common/button/Button.types";

const props = defineProps<{
  modelValue?: boolean;
  labels: [label1: string, label2: string];
  disabled?: boolean;
  color?: Color;
  size: ButtonSize;
  icons: [IconNames, IconNames];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const isDisabled = computed(() => props.disabled);

const iconSize = computed(() => {
  switch (props.size) {
    case ButtonSize.xs:
      return IconSize.xs;
    case ButtonSize.sm:
      return IconSize.sm;
    case ButtonSize.md:
      return IconSize.md;
    case ButtonSize.lg:
      return IconSize.lg;
    case ButtonSize.xl:
      return IconSize.xl;
    default:
      return IconSize.md;
  }
});

const enabledInternal = computed({
  get: () => props.modelValue,
  set: (value) => {
    if (isDisabled.value) return;
    emit("update:modelValue", value);
  },
});

if (props.modelValue === undefined) {
  enabledInternal.value = false;
}
</script>
