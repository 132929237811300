<template>
  <div class="flex h-full max-w-2xl flex-col gap-8">
    <div class="flex flex-col gap-2">
      <PageHeading>{{ textsScanConfig.title }}</PageHeading>
      <PageSubTitle> {{ textsScanConfig.subTitle }}</PageSubTitle>
    </div>

    <List>
      <ListItem
        v-for="location of settings.activityLocations"
        :key="location.id"
        :title="location.name"
        clickable
        @click="loadScanConfiguration(activity, location)"
      >
        <ListItemColumn :flex="0">
          <Icon icon="location_on" />
        </ListItemColumn>
        <ListItemColumn :flex="1" accent>
          <span class="truncate"> {{ location.name }}</span>
        </ListItemColumn>
      </ListItem>
    </List>

    <SlideOver v-model:visible="panelVisible" title="Details">
      <SlideOverProfile
        v-if="scanConfiguration"
        :isLoading="loadingConfiguration"
        :title="textsScanConfig.title"
        :subTitle="textsScanConfig.subTitle"
        :imageUrl="`data:image/png;base64,${scanConfiguration?.qrCode}`"
        :titleImage="summitScanLogoSrc"
        :details="scanConfigurationDetails"
      >
        <template v-slot:actions>
          <div class="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
            <Button
              class="w-full flex-1"
              data-testid="download-config"
              :label="textsScanConfig.downloadQR"
              :loading="downloadingFile"
              @click="downloadFile(activity)"
            />
          </div>
        </template>
      </SlideOverProfile>
    </SlideOver>
  </div>
</template>
<script setup lang="ts">
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { ref } from "vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import SlideOverProfile from "@/components/common/slideover/SlideOverProfile.vue";
import texts from "@/utils/texts";
import Button from "@/components/common/button/Button.vue";
import Notify from "@/utils/notify";
import summitScanLogoSrc from "@/assets/images/logo-summit-scan.svg";
import { downloadConfigurationFile } from "@/services/scanConfiguration.service";
import settings from "@/store/context/settings.context";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import {
  ActivityDTO,
  ActivityLocationLocalizedDTO,
  ScanConfigurationDto,
} from "@/lib/eduConfigurationServiceClient";
import Icon from "@/components/common/icon/Icon.vue";

defineProps<ActivityRouteProps>();

const panelVisible = ref(false);
const loadingConfiguration = ref(false);
const downloadingFile = ref(false);

const textsScanConfig = texts.scanConfiguration.config;

const scanConfiguration = ref<ScanConfigurationDto>();
const scanConfigurationDetails = ref<{ title: string; content: string }[]>();
const currentLocation = ref<ActivityLocationLocalizedDTO>();
const loadScanConfiguration = async (
  settings: ActivityDTO,
  location: ActivityLocationLocalizedDTO,
) => {
  try {
    loadingConfiguration.value = true;
    scanConfiguration.value =
      await eduConfigurationServiceClient.getScanConfiguration(
        settings.id,
        location.id,
      );
    scanConfigurationDetails.value = [
      {
        title: textsScanConfig.details.activity,
        content: settings.name,
      },
      {
        title: textsScanConfig.details.location,
        content: location.name,
      },
    ];
    panelVisible.value = true;
    currentLocation.value = location;
  } catch (err) {
    Notify.failure(texts.notifications.open.failure, [
      texts.models.scanConfiguration.title,
    ]);
    throw err;
  } finally {
    loadingConfiguration.value = false;
  }
};

const downloadFile = async (settings: ActivityDTO) => {
  if (!currentLocation.value) return;
  try {
    downloadingFile.value = true;
    await downloadConfigurationFile(settings.id, currentLocation.value.id);
  } catch (error) {
    Notify.failure(texts.scanConfiguration.downloadFile.failure);
    throw error;
  } finally {
    downloadingFile.value = false;
    panelVisible.value = false;
  }
};
</script>
