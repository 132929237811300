<template>
  <Page class="flex flex-col gap-10">
    <div class="flex flex-col items-start gap-4">
      <PersonalGreeting />
      <div
        v-if="dashboardSelection && context"
        class="flex w-full flex-col flex-wrap justify-between gap-4 sm:flex-row sm:items-center"
      >
        <div class="flex flex-col gap-2 sm:flex-row">
          <SelectionPeriodFilter
            v-model:definition="dashboardSelection"
            :recruitmentYear="context.recruitmentYear"
            :disabled="loadingNewSelectionResults"
          />
          <SelectionStudyProgramsFilter
            v-model:definition="dashboardSelection"
            :context="context"
            :disabled="loadingNewSelectionResults"
          />
          <TextButton
            :disabled="loadingNewSelectionResults"
            :label="texts.navigationItems.dashboard.selection.title"
            :size="ButtonSize.sm"
            :color="Color.Lavender"
            @click="showSelectionSlideOver = true"
          >
            <span
              v-if="dashboardSelection"
              class="flex items-center justify-center rounded-full bg-lavender-500 px-1.5 text-xs font-medium text-white"
              >{{ dashboardSelection.criteria.length }}</span
            ></TextButton
          >
        </div>

        <div class="flex flex-col gap-2 sm:flex-row">
          <Button
            v-if="hasPermissions([Permission.MailingsWrite])"
            :label="texts.navigationItems.dashboard.startMailing"
            :color="Color.Lavender"
            :leadingIcon="'campaign'"
            :size="ButtonSize.sm"
            :disabled="loadingNewSelectionResults"
            @click="
              mailingTypePickerVisible = true;
              useDashboardSelectionForMailing = true;
            "
          />

          <DownloadDashboardProspectsExportButton
            v-if="canViewPersonalData()"
            :selection="dashboardSelection"
            :disabled="loadingNewSelectionResults"
          />
        </div>
      </div>
    </div>
    <section
      :class="[
        'flex flex-col gap-10',
        { 'animate-pulse': loadingNewSelectionResults },
      ]"
    >
      <ProspectStats
        :prospectStats="prospectStats"
        :updatingFilter="loadingNewSelectionResults"
      />
      <ProspectCountPerActivityType
        :prospectStats="prospectStats"
        :class="[{ 'opacity-50': loadingNewSelectionResults }]"
      />
    </section>
    <TransitionFade>
      <section v-if="!loadingNewSelectionResults" class="flex flex-col gap-10">
        <DepartmentInterestStatsBar
          :selection="dashboardSelection"
          :data-testid="testIds.dashboard.departmentInterests"
        />
        <div class="grid gap-x-8 gap-y-10 lg:grid-cols-2">
          <GeoChart
            :selection="dashboardSelection"
            :data-testid="testIds.dashboard.geoChart"
          />
          <Top10Schools
            :selection="dashboardSelection"
            :data-testid="testIds.dashboard.top10Schools"
          />
          <PreEducationGraph
            :selection="dashboardSelection"
            :data-testid="testIds.dashboard.preEducationGraph"
          />
          <ReceiveMarketingEmailGraph
            :uniqueProspectOptInCount="prospectStats?.uniqueProspectOptInCount"
            :uniqueProspectOptOutCount="
              prospectStats?.uniqueProspectOptOutCount
            "
            :data-testid="testIds.dashboard.receiveMarketingEmailGraph"
          />
        </div>
      </section>
    </TransitionFade>
  </Page>

  <SelectionSliderOver
    v-if="context"
    v-model:selectionDefinition="dashboardSelection"
    v-model:visible="showSelectionSlideOver"
    :context
  />

  <MailingTypePicker
    v-model:visible="mailingTypePickerVisible"
    :mailingTypeGroups="getDashboardSupportedMailingTypes()"
  />
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import Page from "@/components/common/page/Page.vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { Color } from "@/enums";
import {
  IProspectStatsDTO,
  ProspectStatsDTO,
} from "@/lib/eduConfigurationServiceClient";
import { getProspectStats } from "@/services/report.service";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import DepartmentInterestStatsBar from "@/views/dashboard/components/DepartmentInterestStatsBar.vue";
import PersonalGreeting from "@/views/dashboard/components/PersonalGreeting.vue";
import { ref, watchEffect } from "vue";
import GeoChart from "./dashboard/components/GeoChart.vue";
import PreEducationGraph from "./dashboard/components/PreEducationGraph.vue";
import ProspectCountPerActivityType from "./dashboard/components/ProspectCountPerActivityType.vue";
import ReceiveMarketingEmailGraph from "./dashboard/components/ReceiveMarketingEmailGraph.vue";
import Top10Schools from "./dashboard/components/Top10Schools.vue";
import DownloadDashboardProspectsExportButton from "@/views/dashboard/components/DownloadDashboardProspectsExportButton.vue";
import SelectionSliderOver from "@/views/dashboard/components/SelectionSliderOver.vue";
import ProspectStats from "@/views/dashboard/components/ProspectStats.vue";
import {
  getDashboardSupportedMailingTypes,
  useDashboardSelection,
} from "@/views/dashboard/Dashboard.helpers";
import MailingTypePicker from "@/components/mailing-type-picker/MailingTypePicker.vue";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import SelectionPeriodFilter from "@/components/selection/filter/SelectionPeriodFilter.vue";
import SelectionStudyProgramsFilter from "@/components/selection/filter/SelectionStudyProgramsFilter.vue";
import TextButton from "@/components/common/button/TextButton.vue";
import {
  canViewPersonalData,
  hasPermissions,
} from "@/router/helpers/userCanGoTo";

const prospectStats = ref<IProspectStatsDTO>();

const brochureRequests = ref<number>();
const keepMeInformedRequests = ref<number>();
const thirdPartyProspectSources = ref<number>();
const trialDayRequests = ref<number>();

const prospectCountPerActivityTypeItems = ref<
  { id?: string; title: string; value: number }[]
>([]);

const {
  dashboardSelection,
  loadDefaultDashboardSelection,
  useDashboardSelectionForMailing,
  context,
  loadContext,
} = useDashboardSelection();
loadDefaultDashboardSelection();
loadContext();

const loadingNewSelectionResults = ref(false);

const showSelectionSlideOver = ref(false);
const mailingTypePickerVisible = ref(false);

const updateStatValues = (prospectStatsDTO: ProspectStatsDTO) => {
  prospectStats.value = prospectStatsDTO;

  brochureRequests.value =
    prospectStatsDTO.prospectCountPerInformationRequestType.brochureRequestCount;
  keepMeInformedRequests.value =
    prospectStatsDTO.prospectCountPerInformationRequestType.keepMeInformedRequestCount;
  thirdPartyProspectSources.value =
    prospectStatsDTO.prospectCountPerInformationRequestType.thirdPartyProspectSourcesCount;
  trialDayRequests.value =
    prospectStatsDTO.prospectCountPerInformationRequestType.trialDayRegistrationCount;

  prospectCountPerActivityTypeItems.value =
    prospectStatsDTO.prospectCountPerActivityType.map((activityType) => {
      return {
        id: activityType.id,
        title: activityType.name,
        value: activityType.count,
      };
    });
};

watchEffect(async () => {
  if (!dashboardSelection.value) return;

  loadingNewSelectionResults.value = true;

  const stats = await getProspectStats({
    criteria: dashboardSelection.value.criteria,
    filters: dashboardSelection.value.filters,
  });

  updateStatValues(stats);

  loadingNewSelectionResults.value = false;
});
</script>
