<template>
  <ErrorComponent v-if="error"></ErrorComponent>
  <div v-else class="flex h-full w-full flex-col gap-8">
    <div class="flex w-full items-start justify-between">
      <div class="flex flex-col gap-2">
        <PageHeading>{{ textsRegLink.title }}</PageHeading>
        <PageSubTitle>{{ textsRegLink.description }}</PageSubTitle>
      </div>
      <Button
        v-if="registrationLinks.length > 0"
        :leadingIcon="'add'"
        :iconColor="Color.White"
        :data-testid="testIds.action.create"
        @click="createSlideOver = true"
        >{{ textsRegLink.btnAdd }}
      </Button>
    </div>
    <List v-if="registrationLinks.length > 0" compact>
      <RegistrationListItem
        v-for="regLink in registrationLinks"
        :key="regLink.id"
        :regLink="regLink"
        @edit="clickOpenEditSlideOver(regLink)"
      >
        <Divider />
        <DropdownItem @click="openDeleteModal(regLink.id)">
          <Icon icon="delete" :color="Color.Alert"></Icon>
          <span class="text-alert-500">{{ texts.actions.delete }}</span>
        </DropdownItem>
      </RegistrationListItem>
    </List>

    <div v-else class="w-full max-w-2xl">
      <RegistrationLinkCreate
        :activity="activity"
        :redirectToHub="true"
        @create:success="onCreateSuccess"
        @create:cancel="createSlideOver = false"
      />
    </div>

    <SlideOver
      v-model:visible="createSlideOver"
      :title="textsRegLink.create.slideOver.title"
      :subTitle="textsRegLink.create.slideOver.description"
    >
      <div class="px-5">
        <RegistrationLinkCreate
          :activity="activity"
          :redirectToHub="false"
          @create:success="onCreateSuccess"
          @create:cancel="createSlideOver = false"
        />
      </div>
    </SlideOver>

    <SlideOver
      v-model:visible="editSlideOver"
      :title="editSlideOverTitle"
      :subTitle="textsRegLink.edit.slideOver.description"
    >
      <Loader v-if="openEditLoading"></Loader>
      <RegistrationLinkEdit
        v-if="currentRegistrationLink"
        :regLink="currentRegistrationLink"
        @update:success="onEditSuccess"
        @update:cancel="editSlideOver = false"
        @delete:success="onEditSuccess"
      />
    </SlideOver>
    <DeleteRegistrationLinkModal
      v-if="registrationLinkIdToDelete"
      :deletingId="registrationLinkIdToDelete"
      :activityId="activity.id"
      @update:deletingId="deleteRegistrationLink"
    >
    </DeleteRegistrationLinkModal>
  </div>
</template>

<script setup lang="ts">
import List from "@/components/common/list/List.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import { ref } from "vue";
import RegistrationListItem from "./components/RegistrationListItem.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import texts from "@/utils/texts";
import Loader from "@/components/common/loader/Loader.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import RegistrationLinkEdit from "./components/RegistrationLinkEdit.vue";
import RegistrationLinkCreate from "./components/RegistrationLinkCreate.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import activityContext from "@/store/context/activity.context";
import router from "@/router/index";
import { RouteNamesActivityReports } from "@/router/routeNames";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import Button from "@/components/common/button/Button.vue";
import { testIds } from "@/utils/testing";
import {
  RegistrationLinkDTO,
  RegistrationLinkWithRegistrationCountDTO,
} from "@/lib/eduConfigurationServiceClient";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { Color } from "@/enums";
import DeleteRegistrationLinkModal from "@/views/organize/activities/activity/registration-links/components/DeleteRegistrationLinkModal.vue";
import Icon from "@/components/common/icon/Icon.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { loadForms } from "@/store/context/settings.context";

const props = defineProps<ActivityRouteProps>();

const textsRegLink = texts.navigationItems.organize.activity.registrationLinks;

// Fetch registration links
const error = ref(false);
const fetchRegistrationLinks = async () => {
  try {
    activityContext.registrationLinks =
      await eduConfigurationServiceClient.getAllRegistrationLinksWithRegistrationCounts(
        props.id,
      );
  } catch (e) {
    error.value = true;
    throw e;
  }
};

// Create
const createSlideOver = ref(false);
const onCreateSuccess = async () => {
  await fetchRegistrationLinks();
  await loadForms();
  createSlideOver.value = false;
  if (props.registrationLinks.length === 1) {
    router.push({
      name: RouteNamesActivityReports.OVERVIEW,
      params: { id: props.id },
    });
  }
};

// Edit
const editSlideOver = ref(false);
const editSlideOverTitle = ref("");
const currentRegistrationLink = ref<RegistrationLinkDTO>();

const openEditLoading = ref(false);
const clickOpenEditSlideOver = async (
  registrationLink: RegistrationLinkWithRegistrationCountDTO,
) => {
  openEditLoading.value = true;
  currentRegistrationLink.value = undefined;
  editSlideOverTitle.value = registrationLink.name;
  editSlideOver.value = true;

  try {
    currentRegistrationLink.value =
      await eduConfigurationServiceClient.getRegistrationLink(
        props.id,
        registrationLink.id,
      );
  } catch (err) {
    editSlideOver.value = false;
    Notify.failure(textsRegLink.getRegistrationLink.failure);
    throw err;
  } finally {
    openEditLoading.value = false;
  }
};

const onEditSuccess = async () => {
  await fetchRegistrationLinks();
  currentRegistrationLink.value = undefined;
  editSlideOver.value = false;
};

const registrationLinkIdToDelete = ref<string | undefined>(undefined);
// Delete registration link
const openDeleteModal = (registrationLinkId: string) => {
  registrationLinkIdToDelete.value = registrationLinkId;
};

const deleteRegistrationLink = () => {
  registrationLinkIdToDelete.value = undefined;
  fetchRegistrationLinks();
};
</script>
