<template>
  <div v-if="showSettings">
    <span class="font-semibold">{{ texts.models.question.otherSettings }}</span>
    <FormFieldTextInput
      id="minNumberOfAnswers"
      class="mt-2 w-24 text-nowrap font-medium"
      :type="TextInputType.NUMBER"
      :label="texts.models.question.minAnswers"
    />
    <FormFieldTextInput
      id="maxNumberOfAnswers"
      class="mt-2 w-24 text-nowrap font-medium"
      :type="TextInputType.NUMBER"
      :label="texts.models.question.maxAnswers"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import texts from "@/utils/texts";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import { FormContext } from "vee-validate";
import { QuestionEditorFormValues } from "@/views/settings/questions/components/question-settings/QuestionSettings.types";

const props = defineProps<{
  form: FormContext<QuestionEditorFormValues, QuestionEditorFormValues>;
  destination: string;
}>();

const showSettings = computed(() => {
  // Unfortunately, types are mapped as flat strings.
  // We can't hook into the QuestionType enum right now.
  const relevantTypes = ["CheckBox", "FilterableCheckbox"];

  return (
    relevantTypes.includes(props.form.values.type) &&
    props.destination !== "Session"
  );
});
</script>
