<template>
  <Section>
    <template v-slot:heading>
      <SectionHeading
        :title="texts.navigationItems.mailing.steps.timing.title"
        :description="texts.navigationItems.mailing.steps.timing.description"
        :divider="false"
      />
    </template>
    <DateTimeField
      data-testid="datetime"
      formFieldId="datetime"
      :label="texts.models.mailing.timing"
      :max="maximumDateTimeValue"
    />
  </Section>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Culture, MailingType } from "@/enums";
import dictionary from "@/dictionary";

import {
  ConceptMailingData,
  MailingContextData,
} from "@/views/mailings/mailing/MailingWizard.types";
import { useForm } from "vee-validate";
import * as yup from "yup";
import DateTimeField from "@/components/common/datetime/DateTimeField.vue";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import { DateTime } from "luxon";

const emit = defineEmits([
  "forwardNavigationValidated",
  "backNavigationValidated",
  "saveValidated",
]);

const props = defineProps<{
  isNavigatingBack: boolean;
  isNavigatingForward: boolean;
  isSaving: boolean;
  data: ConceptMailingData;
  contextData: MailingContextData;
}>();

// Store setup
const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const maximumDateTimeValue = computed((): DateTime | undefined => {
  if (props.data.type === MailingType.ActivityReminder) {
    return props.data.activity?.endDateTime;
  }
  return undefined;
});

const form = useForm({
  validationSchema: yup.object({
    datetime: yup
      .mixed<DateTime>()
      .required()
      .test(
        "reminderEndDate",
        texts.validation.reminderDateNeedsToBeBeforeActivityEndDateTime,
        (date) =>
          props.data.type !== MailingType.ActivityReminder ||
          (props.data.type === MailingType.ActivityReminder &&
            !!date &&
            !!maximumDateTimeValue.value &&
            date <= maximumDateTimeValue.value),
      )
      .test(
        "visitedStartDate",
        texts.validation.surveyDateNeedsToBeAfterActivityStartDateTime,
        (date) =>
          props.data.type !== MailingType.ActivityVisitedSurvey ||
          (props.data.type === MailingType.ActivityVisitedSurvey &&
            !!date &&
            !!props.data.activity?.startDateTime &&
            date > props.data.activity?.startDateTime),
      ),
  }),
});

const internalData = computed(() => props.data);

form.setValues({
  datetime: internalData.value.datetime,
});

const getValues = () => {
  internalData.value.datetime = form.values.datetime;
};

// Navigation
watch(
  () => props.isNavigatingForward,
  async (value) => {
    if (value) {
      const result = await form.validate();
      getValues();

      emit("forwardNavigationValidated", result.valid);
    }
  },
);

watch(
  () => props.isNavigatingBack,
  (value) => {
    if (value) {
      emit("backNavigationValidated", true);
    }
  },
);

watch(
  () => props.isSaving,
  async (value) => {
    if (value) {
      getValues();

      emit("saveValidated", true);
    }
  },
);
</script>
