<template>
  <Page>
    <div class="flex flex-col gap-4">
      <SectionHeading
        :title="texts.navigationItems.mailing.steps.selectionDefinition.title"
        :description="
          texts.navigationItems.mailing.steps.selectionDefinition.description
        "
        :divider="false"
      />
      <div class="flex max-w-3xl flex-col gap-4">
        <template v-if="internalData.selectionDefinition">
          <SelectionDefinition
            v-model="internalData.selectionDefinition"
            :context="selectionContext"
          >
            <SelectionCountForMailing
              :definition="internalData.selectionDefinition"
              @results="(value) => (selectionResults = value)"
            />
          </SelectionDefinition>

          <MailingRecipientsAlert
            v-if="selectionResults"
            data-testid="prospect-count-description"
            :selectionResultCounts="selectionResults"
          />
        </template>
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Culture } from "@/enums";
import dictionary from "@/dictionary";
import {
  ConceptMailingData,
  createNewDefinitionForMailingType,
  MailingContextData,
} from "@/views/mailings/mailing/MailingWizard.types";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import Page from "@/components/common/page/Page.vue";
import MailingRecipientsAlert from "@/views/mailings/mailing/components/MailingRecipientsAlert.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import logger from "@/plugins/logger";
import { SessionsForActivity } from "@/models/session";
import { CriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import {
  LocalizedActivityDTO,
  SelectionResultsCountDto,
} from "@/lib/eduConfigurationServiceClient";
import SelectionDefinition from "@/components/selection/SelectionDefinition.vue";
import SelectionCountForMailing from "@/views/mailings/mailing/steps/components/SelectionCountForMailing.vue";

const emit = defineEmits([
  "forwardNavigationValidated",
  "backNavigationValidated",
  "saveValidated",
]);

const props = defineProps<{
  isNavigatingBack: boolean;
  isNavigatingForward: boolean;
  isSaving: boolean;
  data: ConceptMailingData;
  contextData: MailingContextData;
}>();

// Store setup
const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const internalData = computed<ConceptMailingData>(() => props.data);

const selectionContext = computed<CriterionFieldContext>(() => ({
  recruitmentYear: props.contextData.recruitmentYear,
  activities: props.contextData.activities,
  studyPrograms: props.contextData.studyPrograms,
  targetAudiences: props.contextData.targetAudiences,
  languages: props.contextData.languages,
  questionsWithAnswers: props.contextData.questionsWithAnswers,
  sessions: sessionsForActivity.value,
  mailings: props.contextData.mailings,
  preEducationLevels: props.contextData.preEducationLevels,
  thirdPartyProspectSources: props.contextData.thirdPartyProspectSources,
}));

const sessionsForActivity = ref<SessionsForActivity>({
  activityId: props.data.activity?.id,
  sessions: [],
});

watch(
  () => internalData.value.activity?.id,
  (activityId?: string) => {
    internalData.value.selectionDefinition = createNewDefinitionForMailingType(
      props.contextData.mailingType,
      internalData.value.selectionDefinition,
      activityId,
    );
  },
  { immediate: true },
);

const fetchSessionsForActivity = async (activity?: LocalizedActivityDTO) => {
  if (activity) {
    try {
      const sessions = await eduConfigurationServiceClient.getSessions(
        activity.id,
      );

      sessionsForActivity.value = { activityId: activity.id, sessions };
    } catch (error) {
      logger.error(error);
    }
  }
};

if (
  props.contextData.selectionDefinition &&
  internalData.value.selectionDefinition
) {
  // Some selection definition was provided as context. Take its criteria and filters
  internalData.value.selectionDefinition.criteria = [
    ...internalData.value.selectionDefinition.criteria,
    ...props.contextData.selectionDefinition.criteria,
  ];

  internalData.value.selectionDefinition.filters =
    props.contextData.selectionDefinition.filters;
}

const selectionResults = ref<SelectionResultsCountDto>();

watch(
  () => props.data.activity,
  (activity) => {
    fetchSessionsForActivity(activity);
  },
  { immediate: true },
);

// Navigation
watch(
  () => props.isNavigatingForward,
  (value) => {
    if (value) {
      emit(
        "forwardNavigationValidated",
        internalData.value.selectionDefinition &&
          internalData.value.selectionDefinition.criteria.length > 0,
      );
    }
  },
);

watch(
  () => props.isNavigatingBack,
  (value) => {
    if (value) {
      emit("backNavigationValidated", true);
    }
  },
);

watch(
  () => props.isSaving,
  async (value) => {
    if (value) {
      emit("saveValidated", true);
    }
  },
);
</script>
