<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-12">
    <Statistics
      :hasHardCopyBrochures="hasHardCopyBrochures"
      :color="pageColor"
    />
    <LayoutTwoCol v-if="overview && studyPrograms">
      <template v-slot:left>
        <BrochureFilter
          :items="overview"
          :studyPrograms="studyPrograms"
          :color="pageColor"
          @filter="(event) => (filteredOverview = event)"
        />
      </template>

      <div class="flex flex-col gap-3">
        <div class="flex items-center justify-between gap-4">
          <h3 class="mb-5 text-xl font-semibold text-deepteal-500 xl:text-2xl">
            {{
              [...new Set(filteredOverview.map((o) => o.brochure.id))].length
            }}
            {{ texts.models.brochure.plural }}
          </h3>
          <div v-show="canWrite" class="flex items-center gap-2">
            <DropdownWrapper :origin="DropdownOrigin.TopRight">
              <template v-slot:button>
                <MenuButton :as="ButtonAdd" />
              </template>
              <template v-slot:items>
                <DropdownItem @click="slideOverCreateOpen = true">
                  <Icon icon="add" />
                  {{ componentTexts.overview.newBrochureBtn }}
                </DropdownItem>
                <DropdownItem @click="slideOverConfigurationCreateOpen = true">
                  <Icon icon="forms_add_on" />
                  {{ componentTexts.overview.newConfigurationBtn }}
                </DropdownItem>
              </template>
            </DropdownWrapper>
          </div>
        </div>
        <span
          v-if="!loading && overview.length === 0"
          data-testid="no-brochures-found-message"
          class="text-sm italic text-gray-500"
        >
          {{ componentTexts.overview.noResultsFound }}
        </span>
        <div class="flex flex-col gap-3">
          <template
            v-for="(items, key) in brochuresPerConfiguration"
            :key="key"
          >
            <BrochureItemConfiguration
              v-if="configurations.length > 0"
              :canWrite="canWrite"
              :configuration="
                items[0]?.configuration ??
                configurations.find((c) => c.id === key)!
              "
              :items="items"
              :color="pageColor"
              @configuration:update="
                () => openUpdateConfigurationSlideOver(key.toString())
              "
              @item:update="(value) => openUpdateBrochureSlideOver(value)"
              @item:delete="(value) => openDeleteModal(value)"
            />
          </template>
        </div>
      </div>
    </LayoutTwoCol>
    <DeleteBrochureModal
      v-if="brochureIdToDelete"
      :deletingId="brochureIdToDelete"
      @update:deletingId="deleteBrochure"
    />
  </Page>

  <SlideOverBrochureCreate
    v-model:visible="slideOverCreateOpen"
    :loading="brochureLoading"
    @create:succeeded="() => fetchBrochures()"
  />
  <SlideOverBrochureEdit
    v-model:visible="slideOverUpdateOpen"
    :brochure="currentBrochure"
    :loading="brochureLoading"
    @update:succeeded="() => fetchBrochures()"
    @delete:succeeded="() => fetchBrochures()"
  />

  <SlideOverBrochureConfigurationCreate
    v-model:visible="slideOverConfigurationCreateOpen"
    :selectedFormIds="
      configurations.filter((c) => c.formId).map((c) => c.formId!)
    "
    @create:succeeded="() => fetchBrochures()"
  />
  <SlideOverBrochureConfigurationUpdate
    v-model:visible="slideOverConfigurationUpdateOpen"
    :configuration="configuration"
    :selectedFormIds="
      configurations
        .filter((c) => c.id != configuration?.id && c.formId)
        .map((c) => c.formId!)
    "
    :loading="loading"
    @update:succeeded="() => fetchBrochures()"
  />
</template>

<script setup lang="ts">
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import Icon from "@/components/common/icon/Icon.vue";
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import Page from "@/components/common/page/Page.vue";
import { Color } from "@/enums";
import {
  BrochureConfigurationDto,
  BrochureDto,
  Permission,
} from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import settings from "@/store/context/settings.context";
import { groupBy } from "@/utils/array";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import {
  BrochureOverviewItem,
  toBrochureOverview,
} from "@/views/information-requests/brochures/Overview.types";
import { MenuButton } from "@headlessui/vue";
import { DateTime } from "luxon";
import { computed, ref } from "vue";
import BrochureFilter from "./components/BrochureFilter.vue";
import BrochureItemConfiguration from "./components/BrochureItemConfiguration.vue";
import DeleteBrochureModal from "./components/DeleteBrochureModal.vue";
import SlideOverBrochureConfigurationCreate from "./components/SlideOverBrochureConfigurationCreate.vue";
import SlideOverBrochureConfigurationUpdate from "./components/SlideOverBrochureConfigurationUpdate.vue";
import SlideOverBrochureCreate from "./components/SlideOverBrochureCreate.vue";
import SlideOverBrochureEdit from "./components/SlideOverBrochureEdit.vue";
import Statistics from "./components/Statistics.vue";

const componentTexts = texts.navigationItems.brochures;

const loading = ref<boolean>(true);
const error = ref<boolean>(false);
const pageColor = Color.DeepTeal;

const fromDate = ref<DateTime>(DateTime.now());
const toDate = ref<DateTime>(DateTime.now());

// Store setup
const canWrite = hasPermissions([Permission.BrochuresWrite]);

getRecruitmentYear()
  .then((recruitmentYear) => {
    const currentRecruitmentYearDates =
      recruitmentYear.toCurrentRecruitmentYearDates();

    fromDate.value = currentRecruitmentYearDates.start;
    toDate.value = currentRecruitmentYearDates.end;
  })
  .then(() => {
    fetchBrochures();
  })
  .catch((e) => {
    error.value = true;
    logger.error(e);
  })
  .finally(() => {
    loading.value = false;
  });

// Get Brochures & StudyPrograms & Configuration
const overview = ref<Array<BrochureOverviewItem>>([]);
const filteredOverview = ref<Array<BrochureOverviewItem>>([]);
const studyPrograms = settings.studyPrograms;
const configurations = ref<BrochureConfigurationDto[]>([]);
const configuration = ref<BrochureConfigurationDto>();

const brochuresPerConfiguration = computed(() => {
  const brochuresWithConfig = groupBy(
    filteredOverview.value.filter((o) => o.configuration.id),
    (item) => item.configuration.id,
  );

  const brochuresWithoutConfig = groupBy(
    filteredOverview.value.filter((o) => o.isOrphaned && !o.configuration.id),
    (item) => item.configuration.name,
  );

  const allConfigs: Record<string, BrochureOverviewItem[]> = {};
  configurations.value.forEach((group) => {
    allConfigs[group.id] = brochuresWithConfig[group.id] || [];
  });

  return { ...allConfigs, ...brochuresWithoutConfig };
});

const fetchBrochures = () => {
  loading.value = true;
  Promise.all([
    eduConfigurationServiceClient.getBrochuresForOverview(
      fromDate.value,
      toDate.value,
    ),
    eduConfigurationServiceClient.getAllBrochureConfigurations(),
  ])
    .then(([brochuresResponse, configResponse]) => {
      overview.value = toBrochureOverview(configResponse, brochuresResponse);
      configurations.value = configResponse;
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

// Create Slide Over
const slideOverCreateOpen = ref<boolean>(false);

// Edit Slide Over
const slideOverUpdateOpen = ref<boolean>(false);
const brochureLoading = ref<boolean>(false);

const currentBrochure = ref<BrochureDto | undefined>(undefined);

const openUpdateBrochureSlideOver = async (brochureId: string) => {
  brochureLoading.value = true;
  slideOverUpdateOpen.value = true;

  eduConfigurationServiceClient
    .getBrochureById(brochureId)
    .then((brochure) => (currentBrochure.value = brochure))
    .catch((e) => {
      slideOverUpdateOpen.value = false;
      Notify.failure(componentTexts.getBrochure.failure);
      logger.error(e);
    })
    .finally(() => {
      brochureLoading.value = false;
    });
};

// Delete Slide Over/Modal
const brochureIdToDelete = ref<string | undefined>(undefined);
const openDeleteModal = (borchureId: string) => {
  brochureIdToDelete.value = borchureId;
};

const deleteBrochure = () => {
  brochureIdToDelete.value = undefined;
  fetchBrochures();
};

// Configuration Slide Over
const slideOverConfigurationCreateOpen = ref<boolean>(false);
const slideOverConfigurationUpdateOpen = ref(false);
const openUpdateConfigurationSlideOver = (configurationId: string) => {
  slideOverConfigurationUpdateOpen.value = true;

  configuration.value = configurations.value.find(
    (c) => c.id === configurationId,
  );
};

const hasHardCopyBrochures = computed(() =>
  overview.value.some((item) => item.brochure.isHardCopy),
);
</script>
