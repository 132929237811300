export const testIdAttribute = "data-testid";
export const testIds = {
  button: "button",
  search: "search",
  statusOk: "status-ok",
  statusError: "status-error",
  pageHeadingTitle: "page-heading-title",
  downloadButton: "download",
  navigationTabs: "navigation-tabs",
  navigationTab: "navigation-tab",
  dropdownItem: "dropdown-item",
  statsItem: "stats-item",
  listItem: "list-item",
  routerLink: "to-route",
  profileMenu: "profile-menu",
  profileMenuLogout: "profile-menu-logout",
  contacts: {
    contactRow: "search-result-row",
  },
  contact: {
    details: {
      title: "contact-details",
      fullName: "full-name",
      fieldName: "name",
      fieldValue: "value",
    },
    journey: {
      title: "contact-journey",
      item: "contact-journey-item",
      slideout: {
        close: "close-panel-button",
        items: "items",
        item: "item",
        fieldName: "name",
        fieldValue: "value",
        contactMomentType: "contact-moment-type",
        activityName: "activity-name",
        registrationLink: "registration-link",
      },
    },
  },
  action: {
    view: "action-view",
    edit: "action-edit",
    create: "action-create",
    submit: "action-submit",
    confirm: "action-confirm",
    delete: "action-delete",
    closeNotification: "close-notification",
    copyForm: "action-copy-form",
    cancel: "action-cancel",
    copy: "action-copy",
    download: "action-download",
    close: "action-close",
    filter: "action-filter",
    reset: "action-reset",
    all: "action-all",
  },
  actions: "actions",
  form: {
    nameNL: "nameNL",
    nameEN: "nameEN",
    errorMessage: "error-message",
    confirm: "confirm",
  },
  option: "option",
  notification: "notification",
  notificationSuccess: "notification-success",
  notificationFailure: "notification-failure",
  notificationWarning: "notification-warning",
  notificationInfo: "notification-info",
  backToOverview: "action-back-to-overview",
  actionsMenu: "actions-menu",
  tenantName: "tenant-name",
  primaryNavigationItem: "primary-navigation-item",
  loading: "loading",
  error: "error",
  main: "main",
  studyProgramPicker: {
    component: "study-program-picker",
    studyProgramsRow: "study-programs-row",
    studyProgramsLabel: "study-programs-label",
    studyPrograms: "study-programs",
    studyProgram: "study-program",
  },
  studyProgramFilter: {
    department: "studyprogram-filter-department",
  },
  targetAudiencePicker: {
    component: "target-audience-picker",
    targetAudience: "target-audience",
  },
  applicationShell: "application-shell",
  applicationMainView: "application-main-view",
  templates: {
    mailTemplateItem: "mail-template-item",
    formTemplateItem: "form-template-item",
  },
  modal: "modal",
  slideOver: "slide-over",
  count: "count",
  mailingsFilter: "mailings-filter",
  mailingsTableRow: "mailings-table-row",
  items: "items",
  dropdownLabel: "dropdown-label",
  settings: {
    activityTypes: "activity-types",
    activityLocations: "activity-locations",
  },
  targetAudiences: "target-audiences",
  dashboard: {
    totals: "totals",
    informationRequests: "information-requests",
    studyProgramFilter: "study-programs-add-or-update-button",
    activityType: "activity-type",
    departmentInterests: "department-interests",
    departmentInterestDepartment: "department-interest-department",
    departmentInterestStudyProgram: "department-interest-studyprogram",
    geoChart: "geo-chart",
    top10Schools: "top-10-schools",
    preEducationGraph: "pre-education-graph",
    receiveMarketingEmailGraph: "receive-marketing-email-graph",
    actions: {
      resetFilters: "dashboard-reset-filters",
    },
  },
  registrationLinks: {
    item: "registration-link-item",
  },
  session: {
    item: "session-item",
  },
  activitySideBar: {
    main: "activity-sidebar",
    sideBarNavItem: "activity-sidebar-nav-item",
    basicSettings: "activity-sidebar-basic-settings",
    setup: "activity-sidebar-setup",
  },
  chevron: {
    down: "chevron-down",
    up: "chevron-up",
  },
};
